<template>
    <ModalTemplate title="Manage Account" :trigger="trigger" :toggle="toggle">
        <form @submit="updateAccount">
            <div class="form-group">
                <label for="accountname" class="manage_account_name">Name</label>
                <input type="text" placeholder="Name" v-model.trim="name" />
            </div>
            <div class="form-group">
                <label for="accountemail" class="manage_account_email">Email</label>
                <input type="email" placeholder="Email" v-model.trim="email" @keyup="checkEmail" />
            </div>
            <div class="manage-actions">
                <span class="change-pass" @click="openUpdatePassword">Change password</span>
                <span class="delete-acc" @click="openDeleteAccount">Delete account</span>
            </div>
            <button type="submit" class="btn btn-default update-account" :disabled="isButtonDisabled">UPDATE</button>
        </form>
    </ModalTemplate>
</template>

<script>
import ModalTemplate from '../Modals/ModalTemplate'
import store from '../../store'
import {mapState} from 'vuex'
import firebase from 'firebase'
import {setCookie} from '../../utils'
import router from '../../router'

export default {
    name: 'ManageAccount',
    store,
    components: {
        ModalTemplate
    },
    props: ['trigger', 'toggle'],
    computed: {
        ...mapState([
            'user'
        ]),
        isButtonDisabled() {
            return !(this.name.length && this.name !== this.user.name || this.email.length && (this.email !== this.user.email && this.isEmailValid))
        }
    },
    data() {
        return {
            name: '',
            email: '',
            isEmailValid: false
        }
    },
    methods: {
        checkEmail(e) { // same as ForgotPassword.vue and AddUser.vue
            let re = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/gi
            this.isEmailValid = re.test(e.target.value)
        },
        async updateAccount(e) {
            e.preventDefault()
            let newEmail = this.email !== this.user.email
            let newName = this.name !== this.user.name
            try {
                let objArr = {}
                if (newEmail) objArr = {email: this.email}
                if (newName) objArr = {name: this.name}
                if (newEmail && newName) objArr = {email: this.email, name: this.name}
                const userRef = firebase.firestore().collection('users').doc(this.user.uid);
                await userRef.update(objArr)
                let cookieContent = {
                    email: newEmail ? this.email : this.user.email, 
                    name: newName ? this.name : this.user.name, 
                    role: this.user.role, 
                    uid: this.user.uid
                }
                setCookie('USERDETAIL', JSON.stringify(cookieContent))
                this.$emit('success')
                router.go()
            } catch (error) {
                this.$emit('error', error.message)
            }
        },
        openUpdatePassword() {
            this.$emit('updatePass')
        },
        openDeleteAccount() {
            this.$emit('deleteAcc')
        }
    },
    created() {
        this.email = this.user.email
        this.name = this.user.name
    }
}
</script>

<style scoped>
form {
	flex: 1 0;
	overflow: hidden;
}
form input {
    background: #bdbdbd;
    border: none;
    border-radius: 0;
    height: 80px;
    font-size: 20px;
    color: #333;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'Avenir-Medium';
    font-weight: 500;
    display: block;
    width: 100%;
    line-height: 1.42857143;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
form input::placeholder {
    opacity: 1;
    font-family: 'Avenir-Light', sans-serif;
    color: #333;
    font-weight: bold;
}
form input:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgb(255, 226, 132);
}
form button {
    background-color: #ffc106;
    height: 80px;
    width: 100%;
    border: none;
    border-radius: 0;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 40px;
    font-weight: 900;
    font-family: 'Avenir-Medium', sans-serif;
    transition: background-color 500ms, opacity 500ms;
    cursor: pointer;
}
form button:disabled {
    cursor: not-allowed;
    color: #212121;
    opacity: 0.7;
    background-color: #ffd54f;
}
.form-group {
    display: flex;
    align-items: center;
    height: 95px;
}
.form-group label {
    width: 178px;
    padding-right: 13px;
    font-size: 20px;
    color: #333;
    font-family: 'Avenir-Light', sans-serif;
}
.form-group input {
    width: 422px;
}
.manage-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.manage-actions span {
    text-transform: none;
    cursor: pointer;
    font-size: 20px;
    font-weight: 900;
}
.manage-actions span:hover {
    text-decoration: underline;
}
.change-pass {
    color: #ffc107;
    margin-bottom: 15px;
}
.delete-acc {
    color: #f44336;
}
</style>