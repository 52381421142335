<template>
    <ModalTemplate :title="title" :trigger="trigger" :toggle="toggle">
        <form @submit.prevent="deleteAccount" class="delete-account">
            <p>Please type your password below to delete your account permanently. Make sure to understand that this is an irreversible action.</p>
            <input type="password" placeholder="Password" v-model="password">
            <button type="submit" :disabled="password.length < 6">DELETE ACCOUNT</button>
        </form>
    </ModalTemplate>
</template>

<script>
import ModalTemplate from '../Modals/ModalTemplate'
import firebase from 'firebase'
import { mapState } from 'vuex'
import router from '../../router'

export default {
    name: 'DeleteAccount',
    router,
    components: {
        ModalTemplate
    },
    props: ['trigger', 'toggle'],
    computed: {
        ...mapState(['user'])
    },
    data() {
        return {
            title: 'Delete Account',
            password: ''
        }
    },
    methods: {
        async deleteAccount() {
            this.$emit('processing')
            let user = await firebase.auth().currentUser
            try {
                await user.reauthenticateWithCredential(
                    firebase.auth.EmailAuthProvider.credential(
                        this.user.email, 
                        this.password
                    )
                )
                await firebase.firestore().collection('users').doc(this.user.uid).delete()
                await firebase.auth().currentUser.delete()
                router.push('/')
            } catch (error) {
                this.$emit('error', 'deleteAccount', error.message)
                this.password = ''
            }
        }
    }
}
</script>

<style>
.delete-account {
	flex: 1 0;
	overflow: hidden;
}
.delete-account input {
    background: #bdbdbd;
    border: none;
    border-radius: 0;
    height: 80px;
    font-size: 20px;
    color: #333;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'Avenir-Medium';
    font-weight: 500;
    display: block;
    width: 100%;
    line-height: 1.42857143;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.delete-account input::placeholder {
    color: #999;
    opacity: 1;
}
.delete-account input:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgb(255, 226, 132);
}
.delete-account button {
    background-color: #ffc106;
    height: 80px;
    width: 100%;
    border: none;
    border-radius: 0;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 40px;
    font-weight: 900;
    font-family: 'Avenir-Medium', sans-serif;
    transition: background-color 500ms, opacity 500ms;
    cursor: pointer;
}
.delete-account button:disabled {
    cursor: not-allowed;
    color: #212121;
    opacity: 0.7;
    background-color: #ffd54f;
}
</style>