<template>
    <ModalTemplate :trigger="trigger" :toggle="toggle">
        <div id="support">
            <img src="../../assets/sauvara-logo.svg" alt="Sauvara Logo" />
            <div>
                <p>Thank you for being our customer! We are always happy to hear from you!</p>
                <p>If you have any questions or suggestions, please contact us anytime at: </p>
            </div>
            <button>support@sauvara.com</button>
        </div>
    </ModalTemplate>
</template>

<script>
import ModalTemplate from '../Modals/ModalTemplate'

export default {
    name: 'ContactSupport',
    components: {
        ModalTemplate
    },
    props: ['trigger', 'toggle']
}
</script>

<style>
#support {
    display: flex;
    flex-direction: column;
	align-items: center;
	font-family: 'Avenir-Book';
}
#support img {
	height: 108px;
}
#support div {
	width: 600px;
	margin: 30px 0;
	align-items: inherit;
	display: inherit;
    flex-direction: inherit;
    line-height: normal;
}
#support div p {
    margin: 0;
    letter-spacing: 0;
    line-height: normal;
}
#support button {
	width: 240px;
	height: 50px;
	background-color: unset;
	border: solid 1px #333;
	font-weight: 900;
	padding: 0;
	margin-bottom: 10px;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    color: #333;
    line-height: 1.42;
}
@media screen and (max-width: 643px) {
	#support {
		height: 373px;
	}
	#support div {
		width: 100%;
	}
	#support img {
		height: auto;
		max-width: 100%;
	}
}
</style>