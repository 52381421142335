import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase";
import router from "../router";
import { setUserCookies, setCookie, getCookie, getDate } from "../utils";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isUserLoggedIn: false,
    user: null,
    isFirstAccess: null,
    loginError: null,
    rawData: [],
    displayData: [],
    totalOfItems: null,
    isFetchingFlashings: false,
    currentlySelectedFlashings: [],
    flashingUnderEdit: null,
    lastUpdatedBy: {}
  },
  actions: {
    async logUserIn({ commit }, [email, password, shouldRememberLoginDetails]) {
      const firestoreUsersCollection = firebase.firestore().collection("users");
      try {
        let docRef = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password);
        let firestoreUser = firestoreUsersCollection.doc(docRef.user.uid);
        let doc = await firestoreUser.get();
        setUserCookies(doc.data(), shouldRememberLoginDetails);
        if (doc.data().isFirstAccess) {
          commit("firstAccess", true);
          setCookie("USERDETAIL", "", 0);
        } else {
          await firestoreUser.update({
            lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
          });
          commit("firstAccess", false);
          commit("setUser");
          router.push("/flashings");
        }
      } catch (error) {
        setCookie("DOCDETAIL", "", 0);
        switch (error.code) {
          case "auth/user-not-found":
            commit(
              "setError",
              "There is no user with the provided credential. Please try again."
            );
            break;
          case "auth/invalid-email":
          case "auth/wrong-password":
            commit(
              "setError",
              "The e-mail or password is invalid. Please try again."
            );
            break;
          default:
            // One of the reasons here is when the user is disabled.
            commit(
              "setError",
              "Unable to authenticate. Please contact the Admin Console administrator."
            );
        }
      }
    },
    async logUserOut({ commit }) {
      try {
        await firebase.auth().signOut();
        setCookie("USERDETAIL", "", 0);
        commit("logUserOut");
        router.push("/");
      } catch (error) {
        // console.error(error.message)
      }
    },
    getCurrentUserInfo({ commit }) {
      commit("setUser");
    },
    resetFirstAccess({ commit }) {
      commit("firstAccess", null);
    },
    async fetchFlashings({ commit }) {
      commit("startFetching");
      const dataSet = [];
      const lastUpdatedBy = {}
      await firebase
        .firestore()
        .collection("flashings")
        .orderBy("updatedAt", "desc")
        .get()
        .then(async (querySnapshot) => {
          commit("setTotalOfItems", querySnapshot.size);
          querySnapshot.forEach((doc) => {
            dataSet.push({
              name: doc.data().name,
              sku: doc.data().sku,
              minPipeSizeIn: doc.data().minPipeSizeIn,
              maxPipeSizeIn: doc.data().maxPipeSizeIn,
              minPipeSizeMm: doc.data().minPipeSizeMm,
              maxPipeSizeMm: doc.data().maxPipeSizeMm,
              baseInWidth: doc.data().baseInWidth,
              baseInLength: doc.data().baseInLength,
              baseMmWidth: doc.data().baseMmWidth,
              baseMmLength: doc.data().baseMmLength,
              totalHeightIn: doc.data().totalHeightIn,
              totalHeightMm: doc.data().totalHeightMm,
              roofPitch: doc.data().roofPitch,
              isRound: doc.data().isRound,
              isSquare: doc.data().isSquare,
              isStandard: doc.data().isStandard,
              isRetrofit: doc.data().isRetrofit,
              isEpdm: doc.data().isEpdm,
              isSilicone: doc.data().isSilicone,
              isNonMetal: doc.data().isNonMetal,
              isMetal: doc.data().isMetal,
              siliconeColors: doc.data().siliconeColors,
              epdmColors: doc.data().epdmColors,
              isDraft: doc.data().isDraft,
              imageUrl: doc.data().imageUrl,
              createdAt: getDate(doc.data().createdAt),
              createdBy: doc.data().createdBy,
              updatedAt: getDate(doc.data().updatedAt),
              updatedBy: doc.data().updatedBy,
              uid: doc.id,
            });
          });
          if (dataSet.length > 0) {
            await firebase
              .firestore()
              .collection("users")
              .doc(dataSet[0].updatedBy)
              .get()
              .then(doc => {
                  lastUpdatedBy.userName = doc.data().name,
                  lastUpdatedBy.date = dataSet[0].updatedAt
                })
              }
            commit("getFlashings", {dataSet, lastUpdatedBy});
          });
    },
    selectFlashings({ commit }, currentFlashings) {
      commit("selectCurrentFlashings", currentFlashings);
    },
    fetchUsers({ commit }) {
      commit("startFetching");
      var dataSet = new Array();
      firebase
        .firestore()
        .collection("users")
        .get()
        .then((querySnapshot) => {
          commit("setTotalOfItems", querySnapshot.size);
          querySnapshot.forEach((doc) => {
            if (this.state.user.uid != doc.id) {
              if (doc.data().role === "superadmin") return;

              dataSet.push({
                uid: doc.id,
                name: doc.data().name,
                email: doc.data().email,
                lastLogin: getDate(doc.data().lastLogin),
                role: doc.data().role,
              });
            }
          });
          commit("getFlashings", {dataSet});
        });
    },
  },
  mutations: {
    setError: (state, error) => {
      state.loginError = error;
    },
    setUser: (state) => {
      (state.user = getCookie("USERDETAIL")
        ? JSON.parse(getCookie("USERDETAIL"))
        : ""),
        (state.isUserLoggedIn = state.user ? true : false);
    },
    logUserOut: (state) => {
      (state.isUserLoggedIn = false), (state.user = null);
    },
    firstAccess: (state, value) => {
      state.isFirstAccess = value;
    },
    startFetching: (state) => {
      state.isFetchingFlashings = true;
    },
    setTotalOfItems: (state, total) => {
      state.totalOfItems = total;
    },
    getFlashings: (state, payload) => {
      state.rawData = payload.dataSet;
      // let displayDataArr = [];
      // dataSet.forEach((flashing) => {
      //   let flashingToBeDisplayed = {};
      //   for (let key in flashing) {
      //     flashingToBeDisplayed[key] =
      //       flashing[key] === null ? "" : flashing[key];
      //   }
      //   displayDataArr.push(flashingToBeDisplayed);
      // });
      // state.displayData = displayDataArr;
      state.displayData = payload.dataSet;
      if (payload.lastUpdatedBy) state.lastUpdatedBy = payload.lastUpdatedBy
      state.isFetchingFlashings = false;
    },
    selectCurrentFlashings: (state, flashings) => {
      state.currentlySelectedFlashings = flashings;
    },
  },
  getters: {
    getAllFlashings: (state) => {
      return state.displayData;
    },
    getSingleItem: (state) => {
      let id = state.currentlySelectedFlashings[0];
      let item = state.rawData.find((item) => item.uid === id);
      delete item.createdAt;
      delete item.createdBy;
      delete item.updatedAt;
      delete item.updatedBy;
      return item;
    },
    searchForTerm: (state) => (term, method) => {
      let data = [];
      switch (method) {
        case "complete":
          data = state.displayData.filter((item) => item.isDraft === false);
          break;
        case "incomplete":
          data = state.displayData.filter((item) => item.isDraft === true);
          break;
        default:
          data = state.displayData;
          break;
      }
      return term
        ? data.filter((item) =>
            item.name.toLowerCase().includes(term.toLowerCase())
          )
        : data;
    },
    getFilteredData: (state) => (method, term) => {
      let data = term
        ? state.displayData.filter((item) =>
            item.name.toLowerCase().includes(term.toLowerCase())
          )
        : state.displayData;
      switch (method) {
        case "complete":
          return data.filter((item) => item.isDraft === false);
        case "incomplete":
          return data.filter((item) => item.isDraft === true);
        default:
          return data;
      }
    }
  },
});
