<template>
  <tr :class="index % 2 === 0 ? 'odd' : 'even'" :id="flashing.uid">
    <td
      :class="{
        'fixed-col table-item-name': true,
        odd: index % 2 === 0,
        even: index % 2 !== 0,
      }"
    >
      <label class="input-field">
        <input
          type="checkbox"
          :value="flashing.uid"
          v-model="currentlySelectedFlashings"
          @click="clickSelect"
        />
        <span class="checkmark"></span>
        <span>{{ flashing.name }}</span>
      </label>
    </td>
    <td>{{ flashing.sku }}</td>
    <td>{{ flashing.minPipeSizeIn && `${flashing.minPipeSizeIn}"` }}</td>
    <td>{{ flashing.maxPipeSizeIn && `${flashing.maxPipeSizeIn}"` }}</td>
    <td>{{ flashing.minPipeSizeMm && `${flashing.minPipeSizeMm}mm` }}</td>
    <td>{{ flashing.maxPipeSizeMm && `${flashing.maxPipeSizeMm}mm` }}</td>
    <td>{{ flashing.baseInWidth && `${flashing.baseInWidth}"` }}</td>
    <td>{{ flashing.baseInLength && `${flashing.baseInLength}"` }}</td>
    <td>{{ flashing.baseMmWidth && `${flashing.baseMmWidth}mm` }}</td>
    <td>{{ flashing.baseMmLength && `${flashing.baseMmLength}mm` }}</td>
    <td>{{ flashing.totalHeightIn && `${flashing.totalHeightIn}"` }}</td>
    <td>{{ flashing.totalHeightMm && `${flashing.totalHeightMm}mm` }}</td>
    <td>{{ flashing.roofPitch }}</td>
    <td>
      <img v-if="flashing.isRound === true" src="../../assets/check-mark.svg" />
      <img
        v-else-if="flashing.isRound === false"
        src="../../assets/false-mark.svg"
      />

      <img v-else src="../../assets/question-mark.svg" />
    </td>

    <td>
      <img
        v-if="flashing.isSquare === true"
        src="../../assets/check-mark.svg"
      />
      <img
        v-else-if="flashing.isSquare === false"
        src="../../assets/false-mark.svg"
      />

      <img v-else src="../../assets/question-mark.svg" />
    </td>

    <td>
      <img
        v-if="flashing.isStandard === true"
        src="../../assets/check-mark.svg"
      />
      <img
        v-else-if="flashing.isStandard === false"
        src="../../assets/false-mark.svg"
      />

      <img v-else src="../../assets/question-mark.svg" />
    </td>

    <td>
      <img
        v-if="flashing.isRetrofit === true"
        src="../../assets/check-mark.svg"
      />
      <img
        v-else-if="flashing.isRetrofit === false"
        src="../../assets/false-mark.svg"
      />

      <img v-else src="../../assets/question-mark.svg" />
    </td>

    <td>
      <img v-if="flashing.isEpdm === true" src="../../assets/check-mark.svg" />
      <img
        v-else-if="flashing.isEpdm === false"
        src="../../assets/false-mark.svg"
      />

      <img v-else src="../../assets/question-mark.svg" />
    </td>

    <td>
      <img
        v-if="flashing.isSilicone === true"
        src="../../assets/check-mark.svg"
      />
      <img
        v-else-if="flashing.isSilicone === false"
        src="../../assets/false-mark.svg"
      />

      <img v-else src="../../assets/question-mark.svg" />
    </td>

    <td>
      <img
        v-if="flashing.isNonMetal === true"
        src="../../assets/check-mark.svg"
      />
      <img
        v-else-if="flashing.isNonMetal === false"
        src="../../assets/false-mark.svg"
      />

      <img v-else src="../../assets/question-mark.svg" />
    </td>

    <td>
      <img v-if="flashing.isMetal === true" src="../../assets/check-mark.svg" />
      <img
        v-else-if="flashing.isMetal === false"
        src="../../assets/false-mark.svg"
      />

      <img v-else src="../../assets/question-mark.svg" />
    </td>

    <td>
      <span v-if="flashing.siliconeColors === null">
        <img src="../../assets/question-mark.svg" />
      </span>
      <span v-else>{{ flashing.siliconeColors }}</span>
    </td>

    <td v-if="flashing.epdmColors === null">
      <img src="../../assets/question-mark.svg" />
    </td>

    <td v-else>{{ flashing.epdmColors }}</td>

    <td v-if="user.role === 'superadmin'">
      <span :class="returnClassName(flashing.isDraft)"></span>
    </td>
    <td v-if="user.role === 'superadmin'">{{ flashing.imageUrl }}</td>
    <td v-if="user.role === 'superadmin'">{{ flashing.createdAt }}</td>
    <td v-if="user.role === 'superadmin'">{{ flashing.createdBy }}</td>
    <td v-if="user.role === 'superadmin'">{{ flashing.updatedAt }}</td>
    <td v-if="user.role === 'superadmin'">{{ flashing.updatedBy }}</td>
    <td v-if="user.role === 'superadmin'">{{ flashing.uid }}</td>
  </tr>
</template>

<script>
import { mapState, mapActions } from "vuex";
import tippy, { followCursor, animateFill } from "tippy.js";
import "tippy.js/dist/tippy.css";

export default {
  name: "TableRowFlashings",
  props: ["index", "flashing"],
  computed: {
    ...mapState(["user", "currentlySelectedFlashings", "displayData"]),
  },
  data() {
    return {
      selectedFlashings: [],
    };
  },
  methods: {
    ...mapActions(["selectFlashings"]),
    clickSelect(e) {
      const { value, checked } = e.target;
      this.$emit("clickSelect", value, checked);
    },
    returnClassName(input) {
      return input === true ? "round round-true" : "round round-false";
    },
  },
  mounted() {
    if (this.flashing.imageUrl) {
      const tag = `<label>${this.flashing.name}</label>
            <div class='content-image'>
            <img src=${this.flashing.imageUrl} />
            </div>`;
      tippy(document.getElementById(this.flashing.uid), {
        content: tag,
        allowHTML: true,
        arrow: false,
        followCursor: true,
        animateFill: false,
        animation: "fade",
        placement: "bottom-start",
        popperOptions: {
          strategy: "fixed",
          modifiers: [
            {
              name: "flip",
              options: {
                fallbackPlacements: ["bottom-end", "top-start", "top-end"],
              },
            },
          ],
        },
        theme: "tooltip-inner",
        plugins: [followCursor, animateFill],
      });
    }
  },
};
</script>

<style>
/* Tooltip rules: */

.tippy-box[data-theme~="tooltip-inner"] {
  border-radius: 0;
  width: 250px;
  height: 250px;
  opacity: 1;
}
.tippy-box[data-theme~="tooltip-inner"] label {
  font-family: "Avenir-Book";
  text-align: center;
  padding: 0;
  margin: 0;
  background: none;
  display: block;
  height: auto;
  font-size: 12px;
  color: #212121;
  font-weight: normal;
  width: 100%;
}
.tippy-box[data-theme~="tooltip-inner"] {
  width: 250px;
  height: 250px;
  max-width: 250px;
  border-radius: 0;
  padding: 10px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin: 0;
}
.tippy-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0;
  margin: 10px;
}
.content-image img {
  max-width: 210px;
  max-height: 193px;
  border: none;
  outline: none;
}
.image-tooltip {
  display: none;
}
</style>
