<template>
  <ModalTemplate :title="modalName" :trigger="trigger" :toggle="toggle">
    <!-- Page 1 -->
    <div class="add-form">
      <div class="add-form-tab" v-show="currentPage === 1">
        <div class="requisite">
          <label for="prod_name" class="requisite-name">Name</label>
          <input
            id="prod_name"
            type="text"
            placeholder=""
            v-model="flashingData.name"
          />
        </div>
        <div class="requisite add-form-dropdown" v-if="this.action === 'add'">
          <span class="requisite-name">Number of copies</span>
          <button @click="openNumOfCopies">
            {{ numOfCopies }}<span class="caret"></span>
          </button>
          <ul v-if="isNumOfCopiesOpen">
            <li @click="setNumOfCopies(i)" v-for="i in 30" :key="i">{{ i }}</li>
          </ul>
        </div>
        <div class="requisite">
          <label for="prod_sku" class="requisite-name">SKU</label>
          <input
            id="prod_sku"
            type="text"
            placeholder=""
            v-model="flashingData.sku"
          />
        </div>
        <div class="requisite">
          <label for="baseInWidth" class="requisite-name"
            >Base in inches (Width)</label
          >
          <input
            id="baseInWidth"
            type="text"
            placeholder="E.g., 1/8, 1, or 1-1/2"
            data-metric="in"
            @input="chkFrictionFor"
            v-model="flashingData.baseInWidth"
          />
        </div>
        <div class="requisite">
          <label for="baseInLength" class="requisite-name"
            >Base in inches (Length)</label
          >
          <input
            id="baseInLength"
            type="text"
            placeholder="E.g., 0, 1/8, 1, or 1-1/2"
            data-metric="in"
            @input="chkFrictionFor"
            v-model="flashingData.baseInLength"
          />
        </div>
        <div class="requisite">
          <label for="baseMmWidth" class="requisite-name"
            >Base in millimeters (Width)</label
          >
          <input
            id="baseMmWidth"
            type="text"
            placeholder="E.g, 1 or 170"
            data-metric="mm"
            @input="chkNumberFor"
            v-model="flashingData.baseMmWidth"
          />
        </div>
        <div class="requisite">
          <label for="baseMmLength" class="requisite-name"
            >Base in millimeters (Length)</label
          >
          <input
            id="baseMmLength"
            type="text"
            placeholder="E.g., 0, 1/8, 1, or 1-1/2"
            data-metric="mm"
            @input="chkNumberFor"
            v-model="flashingData.baseMmLength"
          />
        </div>
        <div class="requisite">
          <label for="totalHeightIn" class="requisite-name"
            >Total height in inches</label
          >
          <input
            id="totalHeightIn"
            type="text"
            placeholder="E.g., 1/8, 1, or 1-1/2"
            data-metric="in"
            @input="chkFrictionFor"
            v-model="flashingData.totalHeightIn"
          />
        </div>
        <div class="requisite">
          <label for="totalHeightMm" class="requisite-name"
            >Total height in millimeters</label
          >
          <input
            id="totalHeightMm"
            type="text"
            placeholder="E.g, 1 or 170"
            data-metric="mm"
            @input="chkNumberFor"
            v-model="flashingData.totalHeightMm"
          />
        </div>
      </div>
      <!-- Page 2 -->
      <div class="add-form-tab" v-show="currentPage === 2">
        <div class="requisite">
          <label for="minPipeSizeIn" class="requisite-name"
            >Min. pipe size in inches</label
          >
          <input
            id="minPipeSizeIn"
            type="text"
            placeholder="E.g., 1/8, 1, or 1-1/2"
            data-metric="in"
            @input="chkFrictionFor"
            v-model="flashingData.minPipeSizeIn"
          />
        </div>
        <div class="requisite">
          <label for="maxPipeSizeIn" class="requisite-name"
            >Max. pipe size in inches</label
          >
          <input
            id="maxPipeSizeIn"
            type="text"
            placeholder="E.g., 1/8, 1, or 1-1/2"
            data-metric="in"
            @input="chkFrictionFor"
            v-model="flashingData.maxPipeSizeIn"
          />
        </div>
        <div class="requisite">
          <label for="minPipeSizeMm" class="requisite-name"
            >Min. pipe size in millimeters</label
          >
          <input
            id="minPipeSizeMm"
            type="text"
            placeholder="E.g, 1 or 170"
            data-metric="mm"
            @input="chkNumberFor"
            v-model="flashingData.minPipeSizeMm"
          />
        </div>
        <div class="requisite">
          <label for="maxPipeSizeMm" class="requisite-name"
            >Max. pipe size in millimeters</label
          >
          <input
            id="maxPipeSizeMm"
            type="text"
            placeholder="E.g, 1 or 170"
            data-metric="mm"
            @input="chkNumberFor"
            v-model="flashingData.maxPipeSizeMm"
          />
        </div>
        <div class="requisite multi-input">
          <ul id="select-items">
            <li>
              <div class="check-group">
                <label>
                  <span
                    class="checkmark"
                    data-name="shapeType"
                    :data-checked="JSON.stringify(flashingData.isRound)"
                    data-value="isRound"
                    @click="setRadioValue"
                  >
                    <img
                      v-if="flashingData.isRound === null"
                      src="../../assets/question-mark.svg"
                    />
                    <img
                      v-else-if="flashingData.isRound === true"
                      src="../../assets/check-mark.svg"
                    />
                    <img v-else src="../../assets/false-mark.svg" />
                  </span>
                  <span class="item-name">Round</span>
                </label>
              </div>
              <div class="check-group">
                <label>
                  <span
                    class="checkmark"
                    data-name="shapeType"
                    :data-checked="JSON.stringify(flashingData.isSquare)"
                    data-value="isSquare"
                    @click="setRadioValue"
                  >
                    <img
                      v-if="flashingData.isSquare === null"
                      src="../../assets/question-mark.svg"
                    />
                    <img
                      v-else-if="flashingData.isSquare === true"
                      src="../../assets/check-mark.svg"
                    />
                    <img v-else src="../../assets/false-mark.svg" />
                  </span>
                  <span class="item-name">Square</span>
                </label>
              </div>
            </li>
            <li>
              <div class="check-group">
                <label>
                  <span
                    class="checkmark"
                    data-name="standardType"
                    :data-checked="JSON.stringify(flashingData.isStandard)"
                    data-value="isStandard"
                    @click="setRadioValue"
                  >
                    <img
                      v-if="flashingData.isStandard === null"
                      src="../../assets/question-mark.svg"
                    />
                    <img
                      v-else-if="flashingData.isStandard === true"
                      src="../../assets/check-mark.svg"
                    />
                    <img v-else src="../../assets/false-mark.svg" />
                  </span>
                  <span class="item-name">Standard</span>
                </label>
              </div>
              <div class="check-group">
                <label>
                  <span
                    class="checkmark"
                    data-name="standardType"
                    :data-checked="JSON.stringify(flashingData.isRetrofit)"
                    data-value="isRetrofit"
                    @click="setRadioValue"
                  >
                    <img
                      v-if="flashingData.isRetrofit === null"
                      src="../../assets/question-mark.svg"
                    />
                    <img
                      v-else-if="flashingData.isRetrofit === true"
                      src="../../assets/check-mark.svg"
                    />
                    <img v-else src="../../assets/false-mark.svg" />
                  </span>
                  <span class="item-name">Retrofit</span>
                </label>
              </div>
            </li>
            <li>
              <div class="check-group">
                <label>
                  <span
                    class="checkmark"
                    data-name="siliconType"
                    :data-checked="JSON.stringify(flashingData.isEpdm)"
                    data-value="isEpdm"
                    @click="setRadioValue"
                  >
                    <img
                      v-if="flashingData.isEpdm === null"
                      src="../../assets/question-mark.svg"
                    />
                    <img
                      v-else-if="flashingData.isEpdm === true"
                      src="../../assets/check-mark.svg"
                    />
                    <img v-else src="../../assets/false-mark.svg" />
                  </span>
                  <span class="item-name">EPDM</span>
                </label>
              </div>
              <div class="check-group">
                <label>
                  <span
                    class="checkmark"
                    data-name="siliconType"
                    :data-checked="JSON.stringify(flashingData.isSilicone)"
                    data-value="isSilicone"
                    @click="setRadioValue"
                  >
                    <img
                      v-if="flashingData.isSilicone === null"
                      src="../../assets/question-mark.svg"
                    />
                    <img
                      v-else-if="flashingData.isSilicone === true"
                      src="../../assets/check-mark.svg"
                    />
                    <img v-else src="../../assets/false-mark.svg" />
                  </span>
                  <span class="item-name">Silicone</span>
                </label>
              </div>
            </li>
            <li>
              <div class="check-group">
                <label>
                  <span
                    class="checkmark"
                    data-name="metalType"
                    :data-checked="JSON.stringify(flashingData.isNonMetal)"
                    data-value="isNonMetal"
                    @click="setRadioValue"
                  >
                    <img
                      v-if="flashingData.isNonMetal === null"
                      src="../../assets/question-mark.svg"
                    />
                    <img
                      v-else-if="flashingData.isNonMetal === true"
                      src="../../assets/check-mark.svg"
                    />
                    <img v-else src="../../assets/false-mark.svg" />
                  </span>
                  <span class="item-name">Non-metal</span>
                </label>
              </div>
              <div class="check-group">
                <label>
                  <span
                    class="checkmark"
                    data-name="metalType"
                    :data-checked="JSON.stringify(flashingData.isMetal)"
                    data-value="isMetal"
                    @click="setRadioValue"
                  >
                    <img
                      v-if="flashingData.isMetal === null"
                      src="../../assets/question-mark.svg"
                    />
                    <img
                      v-else-if="flashingData.isMetal === true"
                      src="../../assets/check-mark.svg"
                    />
                    <img v-else src="../../assets/false-mark.svg" />
                  </span>
                  <span class="item-name">Metal</span>
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div style="margin-top:28px;">
          <div class="legend">
            <span class="legend-wrapper">
              <img src="../../assets/question-mark.svg" />
            </span>
            <span
              >= Flashing will be considered incomplete if feature is unknown or
              not specified.</span
            >
          </div>
          <div class="legend">
            <span class="legend-wrapper">
              <img src="../../assets/check-mark.svg" />
            </span>
            <span>= Feature is supported.</span>
          </div>
          <div class="legend">
            <span class="legend-wrapper">
              <img src="../../assets/false-mark.svg" />
            </span>
            <span>= Feature is not supported.</span>
          </div>
        </div>
      </div>
      <!-- Page 3 -->
      <div class="add-form-tab" v-show="currentPage === 3">
        <div class="requisite add-form-dropdown">
          <span class="requisite-name">Roof pitch</span>
          <button @click="openRoofPitch">
            {{ flashingData.roofPitch }}<span class="caret"></span>
          </button>
          <ul v-if="isRoofPitchOpen">
            <li @click="setRoofPitch()"></li>
            <li @click="setRoofPitch(i - 1)" v-for="i in 19" :key="i - 1">
              {{ i - 1 }}/12
            </li>
          </ul>
        </div>
        <InputField
          @upload="uploadImage"
          :default="flashingData.imageUrl"
          :action="action"
          @removedImg="removeImage"
        />
      </div>
      <!-- Page 4 -->
      <div class="add-form-tab" v-show="currentPage === 4">
        <div class="requisite" v-if="flashingData.isEpdm">
          <label for="prod_epdmColors" class="requisite-name"
            >EPDM colors</label
          >
          <input
            id="epdmColors"
            type="text"
            v-model="flashingData.epdmColors"
            @keyup="checkInput"
          />
        </div>
        <div class="requisite" v-if="flashingData.isSilicone">
          <label for="prod_siliconeColors" class="requisite-name"
            >Silicone colors</label
          >
          <input
            id="siliconeColors"
            type="text"
            v-model="flashingData.siliconeColors"
            @keyup="checkInput"
          />
        </div>
        <div class="content-info" v-if="incompleteData.length">
          <p class="requisite-name">
            Attention: This is a incomplete flashing. Incomplete flashings are
            not displayed on the Roof Pitch Calculator apps. Even though you are
            still able to save this item, we recomend that you enter the missing
            information. Flashings that need to be completed later can be
            quickly found and edited by clicking on "Show incomplete flashings"
            button at the top of this page.
          </p>
          <p class="requisite-name">Missing information:</p>
          <ul id="missing-info">
            <li v-for="item in incompleteData" :key="item">{{ item }}</li>
          </ul>
        </div>
      </div>
      <div class="navigation">
        <button type="button" @click="goPrevious" v-if="currentPage > 1">
          Previous
        </button>
        <button
          type="button"
          @click="goNext"
          v-if="currentPage < 4"
          :disabled="!flashingData.name"
        >
          Next
        </button>
        <button
          type="button"
          v-if="currentPage === 4 && action === 'add'"
          @click="saveFlashing"
        >
          Save
        </button>
        <button
          type="button"
          v-else-if="currentPage === 4 && action === 'update'"
          @click="updateFlashing"
        >
          Update
        </button>
      </div>
    </div>
  </ModalTemplate>
</template>

<script>
import ModalTemplate from "../Modals/ModalTemplate";
import InputField from "./InputField";
import firebase from "firebase";
import { mapState } from "vuex";
import router from "../../router";
import defractionate from "defractionate";

export default {
  name: "AddFlashing",
  router,
  components: {
    ModalTemplate,
    InputField,
  },
  props: ["trigger", "toggle", "data", "action"],
  computed: {
    ...mapState(["user"]),
    incompleteData() {
      let notFilled = [];
      const items = {
        name: "Name",
        sku: "SKU",
        baseInWidth: "Base in inches (Width)",
        baseInLength: "Base in inches (Length)",
        baseMmWidth: "Base in millimeters (Width)",
        baseMmLength: "Base in millimeters (Length)",
        minPipeSizeIn: "Min. pipe size in inches",
        maxPipeSizeIn: "Max. pipe size in inches",
        minPipeSizeMm: "Min. pipe size in millimeters",
        maxPipeSizeMm: "Max. pipe size in millimeters",
        totalHeightIn: "Total height in inches",
        totalHeightMm: "Total height in millimeters",
        isRound: "Round",
        isSquare: "Square",
        isStandard: "Standard",
        isRetrofit: "Retrofit",
        isEpdm: "EPDM",
        isSilicone: "Silicone",
        isNonMetal: "Non-metal",
        isMetal: "Metal",
        roofPitch: "Roof pitch",
        imageUrl: "Flashing image",
        epdmColors: "EPDM Colors",
        siliconeColors: "Silicone Colors",
      };
      let flashingItems = new Map(Object.entries(this.flashingData));
      if (!flashingItems.get("isEpdm")) flashingItems.delete("epdmColors");
      if (!flashingItems.get("isSilicone"))
        flashingItems.delete("siliconeColors");
      for (const [name, value] of flashingItems) {
        if (!value && value !== false && value !== 0) {
          let textValue = "";
          switch (items[name]) {
            case "Round":
            case "Square":
              textValue = "Round/Square";
              break;
            case "Standard":
            case "Retrofit":
              textValue = "Standard/Retrofit";
              break;
            case "EPDM":
            case "Silicone":
              textValue = "EPDM/Silicone";
              break;
            case "Non-metal":
            case "Metal":
              textValue = "Non-metal/Metal";
              break;
            default:
              textValue = items[name];
          }
          if (notFilled.indexOf(textValue) === -1) notFilled.push(textValue);
        }
      }
      return notFilled;
    },
    modalName() {
      return this.action === "add" ? "Add Flashing" : "Update Flashing";
    },
  },
  data() {
    return {
      currentPage: 1,
      isNumOfCopiesOpen: false,
      isRoofPitchOpen: false,
      flashingData: {
        name: null,
        sku: null,
        baseInWidth: null,
        baseInLength: null,
        baseMmWidth: null,
        baseMmLength: null,
        totalHeightIn: null,
        totalHeightMm: null,
        minPipeSizeIn: null,
        maxPipeSizeIn: null,
        minPipeSizeMm: null,
        maxPipeSizeMm: null,
        isRound: null,
        isSquare: null,
        isStandard: null,
        isRetrofit: null,
        isEpdm: null,
        isSilicone: null,
        isNonMetal: null,
        isMetal: null,
        roofPitch: null,
        imageUrl: null,
        epdmColors: null,
        siliconeColors: null,
      },
      numOfCopies: 1,
      previousImage: null,
      hasRemovedImg: false,
    };
  },
  methods: {
    async saveFlashing() {
      this.$emit("processing");
      var step = 1;
      while (step <= this.numOfCopies) {
        let newFlashingRef = await firebase
          .firestore()
          .collection("flashings")
          .doc();

        let flashing = { ...this.flashingData };
        flashing.uid = newFlashingRef.id;
        flashing.imageUrl = await this.addFlashingImageAndGetUrl(
          this.flashingData.imageUrl,
          flashing.uid
        );
        const creationTime = firebase.firestore.FieldValue.serverTimestamp();
        flashing.createdAt = creationTime;
        flashing.createdBy = this.user.uid;
        flashing.updatedAt = creationTime;
        flashing.updatedBy = this.user.uid;
        !this.incompleteData.length
          ? (flashing.isDraft = false)
          : (flashing.isDraft = true);

        await newFlashingRef
          .set(flashing)
          .then(function() {
            step++;
          })
          .catch(function(error) {
            alert(error.message);
            return;
          });
      }
      router.go();
    },
    async addFlashingImageAndGetUrl(imageFile, imageFileName) {
      let imgUrl;
      if (imageFile) {
        await firebase
          .storage()
          .ref("images/flashings/" + imageFileName)
          .put(imageFile)
          .then(async (content) => {
            await content.ref.getDownloadURL().then((url) => {
              imgUrl = url;
            });
          });
      }
      return imgUrl ? imgUrl : null;
    },
    async updateFlashing() {
      this.$emit("processing");
      try {
        if (this.flashingData.imageUrl !== this.previousImage)
          this.flashingData.imageUrl = await this.addFlashingImageAndGetUrl(
            this.flashingData.imageUrl,
            this.flashingData.uid
          );
        if (this.hasRemovedImg) {
          firebase
            .storage()
            .ref()
            .child("images/flashings/" + this.flashingData.uid)
            .delete();
          this.flashingData.imageUrl = null;
        }
        this.flashingData.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
        this.flashingData.updatedBy = this.user.uid;
        !this.incompleteData.length
          ? (this.flashingData.isDraft = false)
          : (this.flashingData.isDraft = true);
        for (let key in this.flashingData) {
          this.flashingData[key] =
            this.flashingData[key] === "" ? null : this.flashingData[key];
        }
        await firebase
          .firestore()
          .collection("flashings")
          .doc(this.flashingData.uid)
          .update(this.flashingData);
        router.go();
      } catch (error) {
        alert(error.message);
      }
    },
    goPrevious() {
      this.currentPage > 1 ? this.currentPage-- : (this.currentPage = 1);
    },
    goNext() {
      this.currentPage++;
    },
    openRoofPitch() {
      this.isRoofPitchOpen = !this.isRoofPitchOpen;
    },
    setRoofPitch(num) {
      num === undefined
        ? (this.flashingData.roofPitch = null)
        : (this.flashingData.roofPitch = `${num}/12`);
      this.isRoofPitchOpen = false;
    },
    openNumOfCopies() {
      this.isNumOfCopiesOpen = !this.isNumOfCopiesOpen;
    },
    setNumOfCopies(num) {
      num === undefined ? (this.numOfCopies = null) : (this.numOfCopies = num);
      this.isNumOfCopiesOpen = false;
    },
    setRadioValue(e) {
      let { value, checked } = e.target.dataset;
      if (JSON.parse(checked) === null) {
        this.flashingData[value] = true;
        if (value === "isEpdm") this.flashingData.epdmColors = null;
        if (value === "isSilicone") this.flashingData.siliconeColors = null;
      } else if (JSON.parse(checked)) {
        this.flashingData[value] = false;
        if (value === "isEpdm") this.flashingData.epdmColors = "N/A";
        if (value === "isSilicone") this.flashingData.siliconeColors = "N/A";
      } else {
        this.flashingData[value] = null;
        if (value === "isEpdm") this.flashingData.epdmColors = null;
        if (value === "isSilicone") this.flashingData.siliconeColors = null;
      }
    },
    setCheckboxValue(e) {
      let { value, checked } = e.target;
      switch (value) {
        case "standard":
          if (checked) {
            this.flashingData.isStandard = true;
            if (this.flashingData.isRetrofit !== true) {
              this.flashingData.isRetrofit = false;
            }
          } else {
            if (this.flashingData.isRetrofit === true) {
              this.flashingData.isStandard = false;
            } else {
              this.flashingData.isStandard = null;
              this.flashingData.isRetrofit = null;
            }
          }
          break;
        case "retrofit":
          if (checked) {
            this.flashingData.isRetrofit = true;
            if (this.flashingData.isStandard !== true) {
              this.flashingData.isStandard = false;
            }
          } else {
            if (this.flashingData.isStandard === true) {
              this.flashingData.isRetrofit = false;
            } else {
              this.flashingData.isRetrofit = null;
              this.flashingData.isStandard = null;
            }
          }
          break;
        case "epdm":
          if (checked) {
            this.flashingData.isEpdm = true;
            if (this.flashingData.isSilicone !== true) {
              this.flashingData.isSilicone = false;
            }
          } else {
            if (this.flashingData.isSilicone === true) {
              this.flashingData.isEpdm = false;
            } else {
              this.flashingData.isEpdm = null;
              this.flashingData.isSilicone = null;
            }
          }
          break;
        case "silicone":
          if (checked) {
            this.flashingData.isSilicone = true;
            if (this.flashingData.isEpdm !== true) {
              this.flashingData.isEpdm = false;
            }
          } else {
            if (this.flashingData.isEpdm === true) {
              this.flashingData.isSilicone = false;
            } else {
              this.flashingData.isSilicone = null;
              this.flashingData.isEpdm = null;
            }
          }
          break;
        default:
          break;
      }
    },
    uploadImage(image) {
      this.flashingData.imageUrl = image;
    },
    removeImage() {
      this.flashingData.imageUrl = null;
      this.hasRemovedImg = true;
    },
    checkInput(e) {
      if (!e.target.value) this.flashingData[e.target.id] = null;
    },
    chkFrictionFor(e) {
      let { value, id } = e.target;
      let pair = id.replace("In", "Mm");

      if (!value) {
        this.flashingData[id] = null;
        this.flashingData[pair] = null;
        return;
      }

      if (value == 0) return (this.flashingData[pair] = 0);

      if (this.isFraction(value, id) == false) {
        alert("Only imperial values are allowed, such as 0, 1/8 or 1-1/2");
        e.target.value = "";
        this.flashingData[id] = null;
        this.flashingData[pair] = null;
      } else {
        let converted = Math.ceil(defractionate(value));
        this.flashingData[pair] =
          converted === Number(this.flashingData[pair])
            ? this.flashingData[pair]
            : defractionate(value) || "";
      }
    },
    isFraction(val, id) {
      const regexRegular = /^(\d+)\.?(\d*)$|^\d{0,3}-?\d{0,3}\/?\d{0,3}?$/gi;
      // new regex coming from web calc
      const regexPipeSize = /^((\b([0-9]|1[0-9]|20)\b)?|(\b([1-9]|1[0-9])\b)+(-?)|(\b([13579]|1[135])\b)+\/|(1\/([1248]|1[6]))|(3\/([148]|1[6]))|([57]\/([18]|1[6]))|(([9]|1[135])\/(1|(1[6])))|(\b([1-9]|[1-3][0-9])\b)+(-?)(\b([13579]|1[135])\b)|0\.0([6-9]|[6][2-9]|[7-9][0-9]|[6][2][5-9]|[6][3-9][0-9]|[7-9][0-9][0-9])?|0\.([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][1-9])?|(\b([1-9]|[12][0-9]|3[0-8])\b)+\.([0-9]|[0-9][0-9]|[0-9][0-9][0-9]|[0-9][0-9][0-9][1-9])?|39\.([0-4]|[0-4][0-9]|[0-4][0-9][0-9]|[0-4][0-9][0-9][0-9]|5)?|(\b([1-9]|[1-2][0-9]|3[0-9])\b)(-?)(([13579]|1[135])\/|1[135]\/1|(1\/([1248]|1[6]))|(3\/([48]|1[6]?))|([57]\/([8]|1[6]?))|(([9]|1[135])\/(1[6]?)))?|(39-?1?\/?2?))$/gi;
      return id.includes("PipeSize")
        ? regexPipeSize.test(val)
        : regexRegular.test(val);
    },
    chkNumberFor(e) {
      let { value, id } = e.target;
      let pair = id.replace("Mm", "In");

      if (!value) {
        this.flashingData[id] = null;
        this.flashingData[pair] = null;
        return;
      }

      if (value == 0) return (this.flashingData[pair] = 0);

      if (this.isNum(value, id) == false) {
        alert("Only imperial values are allowed, such as 0 or 170");
        e.target.value = "";
        this.flashingData[id] = null;
        this.flashingData[pair] = null;
      } else {
        let deconverted = Number(defractionate(value));
        this.flashingData[pair] =
          Number(value) === deconverted
            ? this.flashingData[pair]
            : Number(value / 25.4).toFixed(4) || "";
      }
    },
    isNum(val, id) {
      const regexRegular = /^(\d+)\.?(\d*)$/gi;
      // new regex coming from web calc
      const regexPipeSize = /^((\b([1-9]|[1-8][0-9]|9[0-9]|[1-9][0-9]{2}|100[0-3])\b)|(\b([1-9]|[1-8][0-9]|9[0-9]|[1-9][0-9]{2}|100[0-3])\b)\.|1\.([5-9]|[5][8][7-9]|[5][8-9]|[5][9][0-9]|[6-9][0-9]|[6-9][0-9][0-9]|(587[5-9]|58[89][0-9]|59[0-9][0-9]|[6-9][0-9][0-9][0-9]))?|(\b([1-9]|[1-8][0-9]|9[0-9]|[1-9][0-9]{2}|100[012])\b)\.([0-9]|[0-9][0-9]|[0-9][0-9][0-9]|[0-9][0-9][0-9][1-9])?|1003\.(([0-2]|[0-2][0-9]|[0-2][0-9][0-9]|[0-2][0-9][0-9][0-9])|3?)?)?$/gi;
      return id.includes("PipeSize")
        ? regexPipeSize.test(val)
        : regexRegular.test(val);
    },
  },
  created() {
    (this.currentPage = 1),
      (this.isNumOfCopiesOpen = false),
      (this.isRoofPitchOpen = false);
  },
  watch: {
    data() {
      this.flashingData = { ...this.data };
      this.currentPage = 1;
      this.previousImage = this.data.imageUrl;
    },
  },
};
</script>

<style>
.add-form {
  height: 950px;
  display: flex;
  flex-direction: column;
}
.add-form-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: hidden;
}
.requisite {
  display: flex;
  align-items: center;
  color: #333333;
  margin-bottom: 15px;
}
.multi-input {
  margin-top: 40px;
}
.requisite-name {
  flex: 1;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-right: 13px;
  font-weight: 300;
  font-family: "Avenir-Light";
}
.requisite input {
  width: 70%;
  float: left;
  background: #bdbdbd;
  height: 80px;
  border-radius: 0;
  border: none;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  font-family: "Avenir-Medium";
  padding: 10px 30px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.requisite input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(255, 226, 132);
}
.requisite input::placeholder {
  font-size: 16px;
  font-weight: 400;
  font-style: oblique;
  font-family: "Avenir-Light";
  font-stretch: normal;
  letter-spacing: normal;
  color: #333333;
  opacity: 1;
}
.form-group:nth-last-child(1) {
  margin-top: 40px;
}
#select-items {
  float: left;
  width: 100%;
  list-style: none;
}
#select-items li {
  width: 25%;
  float: left;
  display: block;
  border-right: 1px solid rgba(59, 59, 59, 0.2);
  padding-left: 40px;
}
#select-items li:nth-child(1) {
  padding-left: 0;
}
#select-items li:nth-last-child(1) {
  border-right: 0;
}
#select-items input {
  display: none;
}
#select-items .check-group {
  width: 100%;
  display: block;
  float: left;
  margin-bottom: 25px;
}
#select-items label {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212121;
  font-family: "Avenir-Book";
  position: relative;
  cursor: pointer;
}
.item-name {
  padding-left: 11px;
}

/* Create a custom checkbox */
.check-group .checkmark {
  width: 24px;
  height: 24px;
  background-color: #bdbdbd;
  border: 2px solid #bdbdbd;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.check-group .checkmark > img {
  pointer-events: none;
}
/* On mouse-over, add a grey background color */
.check-group label:focus input ~ .checkmark {
  background-color: #ffc106;
  border-color: #ffc106;
  border-radius: 0;
}
/* When the checkbox is checked, add a blue background */
.check-group input:checked ~ .checkmark {
  background-color: #ffc106 !important;
  border-color: #ffc106 !important;
}

.check-group input:checked ~ .checkmark .checkmark-in {
  display: inline-block;
  width: 22px;
  height: 30px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  position: absolute;
  top: -3px;
}

.check-group input:checked ~ .checkmark .checkmark_stem {
  position: absolute;
  width: 3px;
  height: 15px;
  left: 11px;
  background-color: #fff;
  top: 6px;
}

.check-group input:checked ~ .checkmark .checkmark_kick {
  position: absolute;
  width: 8px;
  height: 3px;
  background-color: #fff;
  left: 6px;
  top: 19px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.check-group .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.check-group input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.check-group .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: none;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check-group .checkbox label {
  padding-left: 50px;
}
button.update-product,
button.add-new-entry {
  margin-top: 25px;
}
.norecord {
  width: 100%;
  height: auto;
  z-index: 99999 !important;
  float: left;
  padding: 20px;
  font-size: 20px;
}
.add-form-dropdown {
  position: relative;
}
.add-form-dropdown > span {
  flex: 1;
}
.add-form-dropdown button {
  width: 420px;
  height: 80px;
  background-color: #bdbdbd;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  font-size: 20px;
  font-family: "Avenir-Medium", sans-serif;
  color: #333;
}
.add-form-dropdown button:hover {
  background-color: #333;
  color: #fff;
}
.add-form-dropdown button span {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #333;
  transition: border-top-color 0.5s ease-out;
  display: inline-block;
  position: absolute;
  right: 30px;
}
.add-form-dropdown button:hover span {
  border-top-color: #fff;
}
.add-form-dropdown ul {
  position: absolute;
  right: 0;
  top: 80px;
  width: 420px;
  left: auto;
  background-color: #bdbdbd !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  height: 200px;
  overflow: auto;
  z-index: 10;
}
.add-form-dropdown ul li {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  padding: 0 20px;
  height: 52px;
  display: flex;
  align-items: center;
  transition: background-color 0.5s ease-out;
  text-decoration: none;
  cursor: pointer;
}
.add-form-dropdown ul li:hover {
  background-color: #ffd54f;
}
.content-info {
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 15px 0;
}
.content-info ul {
  overflow: auto;
  flex: 1 auto;
  padding: revert;
}
.content-info p {
  margin: 0 0 10px;
}
.content-info ul li {
  font-size: 20px;
  font-family: "Avenir-Light", sans-serif;
}

/* */
.legend {
  display: flex;
  font-size: 12px;
  margin: 2px 0;
}
.legend-wrapper {
  width: 24px;
  height: 24px;
  border: 1px solid rgba(196, 196, 196, 0.8);
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Navigation */

.navigation {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
.navigation button {
  background-color: #ffd54f;
  padding: 28px 0;
  border: none;
  border-radius: 0;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 900;
  width: 140px;
  font-family: Avenir-medium;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  transition: background-color 0.5s ease-out;
  cursor: pointer;
}
.navigation button:hover {
  background-color: #333;
  color: #fff;
}
.navigation button:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
.navigation button:nth-last-child(1) {
  margin-left: 40px;
}

@media screen and (max-width: 768px) {
  .requisite-name {
    font-size: 16px;
  }
  .requisite input {
    height: 60px;
    font-size: 16px;
    padding: 10px;
  }
  .add-form {
    height: 650px;
  }
  .add-form-dropdown button {
    height: 60px;
    width: 70%;
    padding: 10px;
    font-size: 16px;
  }
  .add-form-dropdown ul {
    width: 70%;
    top: 60px;
  }
  .add-form-dropdown ul li {
    font-size: 16px;
  }
  .navigation button {
    width: 108px;
  }
  #select-items label {
    flex-direction: column;
  }
  #select-items li {
    padding: 0;
  }
  .item-name {
    padding-left: 0;
    padding-top: 5px;
  }
  .content-info ul li {
    font-size: 16px;
  }
}
</style>
