<template>
    <ModalTemplate :title="componentTitle" :trigger="trigger" :toggle="toggle">
        <p>Looks like it is your first access here. Please create a new password in order to continue. You can always recover your password if you forget it.</p>
        <form @submit="setNewPassword" autocomplete="off">
            <input type="password" v-model="newPassword" placeholder="Password" />
            <button type="submit" :disabled="newPassword.length < 6">Confirm</button>
        </form>
    </ModalTemplate>
</template>

<script>
import firebase from 'firebase'
import ModalTemplate from '../Modals/ModalTemplate'
import {mapActions} from 'vuex'

export default {
    name: 'CreateNewPassword',
    components: {
        ModalTemplate
    },
    props: ['trigger', 'toggle', 'email', 'rememberMe'],
    data() {
        return {
            componentTitle: 'Create a new password',
            newPassword: ''
        }
    },
    methods: {
        ...mapActions(['logUserIn']),
        async setNewPassword(e) {
            e.preventDefault()
            this.$emit('processing')
            try {
                const user = firebase.auth().currentUser
                await user.updatePassword(this.newPassword)
                const userRef = firebase.firestore().collection('users').doc(user.uid)
                userRef.update({isFirstAccess: false})
                this.logUserIn([this.email, this.newPassword, this.rememberMe])
            } catch (error) {
                alert(error.message)
            }
        }
    }
}
</script>

<style scoped>
form {
	flex: 1 0;
	overflow: hidden;
}
form input {
    background: #bdbdbd;
    border: none;
    border-radius: 0;
    height: 80px;
    font-size: 20px;
    color: #333;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'Avenir-Medium';
    font-weight: 500;
    display: block;
    width: 100%;
    line-height: 1.42857143;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
form input::placeholder {
    opacity: 1;
    font-family: 'Avenir-Book', sans-serif;
    color: #333;
}
form input:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgb(255, 226, 132);
}
form button {
    background-color: #ffc106;
    height: 80px;
    width: 100%;
    border: none;
    border-radius: 0;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 40px;
    font-weight: 900;
    font-family: 'Avenir-Medium', sans-serif;
    transition: background-color 500ms, opacity 500ms;
    cursor: pointer;
}
form button:disabled {
    cursor: not-allowed;
    color: #212121;
    opacity: 0.7;
    background-color: #ffd54f;
}
</style>