<template>
    <div class="white-box">
        <input 
            type="file" 
            id="input-file-max-fs" 
            class="dropify" 
            data-max-file-size="5M" 
            data-allowed-file-extensions="jpg png bmp" 
            @change="upload"
         /> 
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    name: 'InputBox',
    props: ['action', 'default'],
    methods: {
        upload(e) {
            let file = e.target.files[0]
            this.$emit('upload', file)
        }
    },
    mounted() {
        $('.dropify').dropify({
                messages: {
                    'default': 'Drag and drop or click to browse the master flash image',
                    'replace': 'Drag and drop or click to browse the master flash image',
                    'remove':  'Remove',
                    'error':   'Ooops, something wrong happended.'
                },
                tpl: {
                    wrap:            '<div class="dropify-wrapper"></div>',
                    loader:          '<div class="dropify-loader"></div>',
                    message:         '<div class="dropify-message"><span class="file-icon" /> <p>{{ default }}</p><div class="info-size"><p>PNG, JPG & Bitmap</p><p>Max. size 5MB </p></div></div>',
                    preview:         '<div class="dropify-preview"><span class="dropify-render"></span><div class="dropify-infos"><div class="dropify-infos-inner"><p class="dropify-infos-message">{{ replace }}</p></div></div></div>',
                    filename:        '<p class="dropify-filename"><span class="file-icon"></span> <span class="dropify-filename-inner"></span></p>',
                    clearButton:     '<button type="button" class="dropify-clear">{{ remove }}</button>',
                    errorLine:       '<p class="dropify-error">{{ error }}</p>',
                    errorsContainer: '<div class="dropify-errors-container"><ul></ul></div>'
                }
            })
        if (this.action === 'update') {
            var dropContent = `<input type="file" id="input-file-max-fs" class="dropify" data-max-file-size="5M" data-allowed-file-extensions="jpg png bmp"${this.default ? ` data-default-file="${this.default}"` : ''} data-image-status="initial" @change="upload" />`
            $('.white-box').html(dropContent)
            $('.dropify').dropify()
            $('.dropify').on('change', (e) => this.upload(e))
            $('.dropify-render').html(`<img src="${this.default}">`)
            $('.dropify-message').html('<span class="file-icon" /> <p>Drag and drop or click to browse the master flash image</p><div class="info-size"><p>PNG, JPG & Bitmap</p><p>Max. size 5MB </p></div><p class="dropify-error">Ooops, something wrong happended.</p></div>')
            $('.dropify').dropify().on('dropify.beforeClear', () => {
                return confirm("Do you really want to delete this flashing image?");
            })
            $('.dropify').dropify().on('dropify.afterClear', () => {
                $('#input-file-max-fs').removeAttr('data-default-file')
                $('#input-file-max-fs').removeAttr('data-image-status')
                this.$emit('removedImg')
            })
        }
    }
}
</script>

<style>
.white-box {
	margin: 40px 0 44px;
}

.white-box .dropify-wrapper {
	height: 420px !important;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    border: 2px dashed #bdbdbd;
}

.white-box .dropify-wrapper .dropify-message .info-size {
	margin-top: 50px;
}

@media screen and (max-width: 768px) {
    .white-box .dropify-wrapper {
        max-height: 250px;
    }
    .dropify-wrapper .dropify-message {
        top: 0;
    }
    .dropify-wrapper.touch-fallback .dropify-message {
        padding: 15px 0;
    }
}
</style>