import Vue from 'vue'
import firebase from 'firebase'
import { result } from './firebase-config.json'
import App from './App.vue'
import router from './router'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt, faTrash, faSignOutAlt, faCog, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPencilAlt, faTrash, faSignOutAlt, faCog, faSearch)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

firebase.initializeApp(result.sdkConfig)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
