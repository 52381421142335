<template>
    <ModalTemplate :title="title" :trigger="trigger" :toggle="toggle">
        <form @submit.prevent="updatePassword" class="update-password">
            <div class="form-group">
                <label for="oldpass">Old password</label>
                <input id="oldpass" type="password" @keyup="isValid" v-model="oldPassword" />
            </div>
            <div class="form-group">
                <label for="newpass">New password</label>
                <input id="newpass" type="password" @keyup="isValid" placeholder="Insert 6 digits at least" v-model="newPassword" />
            </div>
            <button type="submit" :disabled="isInputInvalid">UPDATE</button>
        </form>
    </ModalTemplate>
</template>

<script>
import ModalTemplate from '../Modals/ModalTemplate'
import firebase from 'firebase'
import {mapState} from 'vuex'

export default {
    name: 'UpdatePassword',
    components: {
        ModalTemplate
    },
    props: ['trigger', 'toggle'],
    computed: {
        ...mapState(['user'])
    },
    data() {
        return {
            title: "Update Password",
            oldPassword: '',
            newPassword: '',
            isInputInvalid: true
        }
    },
    methods: {
        isValid() {
            if (this.oldPassword.length) return this.isInputInvalid = this.newPassword.length < 6
            return this.isInputInvalid = true
        },
        async updatePassword() {
            this.$emit('processing')
            const user = await firebase.auth().currentUser
            try {
                await user.reauthenticateWithCredential(
                    firebase.auth.EmailAuthProvider.credential(
                        this.user.email, 
                        this.oldPassword
                    )
                )
                await user.updatePassword(this.newPassword)
                this.$emit('success')
            } catch (error) {
                this.$emit('error', 'updatePass', error.message)
            }
            this.oldPassword = ''
            this.newPassword = ''
            this.isInputInvalid = true
        }
    }
}
</script>

<style>
.update-password {
	flex: 1 0;
	overflow: hidden;
}
.update-password input {
    background: #bdbdbd;
    border: none;
    border-radius: 0;
    height: 80px;
    font-size: 20px;
    color: #333;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'Avenir-Medium';
    font-weight: 500;
    display: block;
    width: 100%;
    line-height: 1.42857143;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
#oldpass::placeholder, #newpass::placeholder {
    opacity: 1;
    font-family: 'Avenir-Book', sans-serif;
    color: #333;
    font-style: oblique;
    font-size: 16px;
    font-weight: 400;
}
.update-password input:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgb(255, 226, 132);
}
.update-password button {
    background-color: #ffc106;
    height: 80px;
    width: 100%;
    border: none;
    border-radius: 0;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 40px;
    font-weight: 900;
    font-family: 'Avenir-Medium', sans-serif;
    transition: background-color 500ms, opacity 500ms;
    cursor: pointer;
}
.update-password button:disabled {
    cursor: not-allowed;
    color: #212121;
    opacity: 0.7;
    background-color: #ffd54f;
}
.form-group {
    display: flex;
    align-items: center;
    height: 95px;
}
.form-group label {
    width: 178px;
    padding-right: 13px;
    font-size: 20px;
    color: #333;
    font-family: 'Avenir-Light', sans-serif;
}
.form-group input {
    width: 422px;
}
</style>