<template>
    <section>
        <AddFlashing :trigger="isAddFlashingOpen" :toggle="addFlashing" @processing="toggleLoading" :data="itemData" :action="actionName" />
        <AddUser :trigger="isAddUserOpen" :toggle="addUser" @processing="toggleLoading" :data="itemData" :action="actionName" @error="showErrorMessage" />
        <GenericMessage :trigger="genericMessage.isOpen" :title="genericMessage.title" :message="genericMessage.message" @closeMessage='closeGenericMessage' />
        <ContactSupport :trigger="contactSupport.isModalOpened" :toggle="toggleSupport" />
        <Loading :trigger="isLoading" />
        <div class="sidebar">
            <div class="company-logo">
                <img src="../../assets/logo.svg" alt="Aztec Washer Company">   
            </div>
            <div class="sidebar-menu">
                <ul>
                    <li id="flashings" :class="this.$route.path === '/flashings' ? 'active' : ''"><router-link to="/flashings">Flashings</router-link></li>
                    <li id="users" v-if="user.role === 'superadmin' || user.role === 'admin'" :class="this.$route.path === '/users' ? 'active' : ''"><router-link to="/users">Users</router-link></li>
                </ul>
                <button id="support-button" @click="toggleSupport">Contact support</button>
            </div>
        </div>
        <div class="dashboard-main">
            <Header :currentUser="user.email" @addFlashing="addFlashing" @editFlashing="editFlashing" @filterResults="filterResults" @returnSearch="returnSearch" @addUser="addUser" @editUser="editUser" :pageTitle="pageTitle" />
            <slot></slot>
        </div>
    </section>
</template>

<script>
import store from '../../store'
import Header from './ConsoleHeader'
import ContactSupport from './ContactSupport'
import { mapState, mapGetters } from 'vuex'
import AddFlashing from './AddFlashing'
import AddUser from './AddUser'
import GenericMessage from '../Modals/GenericMessage'
import Loading from '../Modals/Loading'

export default {
    name: 'DashboadTemplate',
    store,
    props: ['pageTitle'],
    components: {
        Header,
        ContactSupport,
        AddFlashing,
        AddUser,
        GenericMessage,
        Loading
    },
    computed: {
        ...mapState([
            'isUserLoggedIn', 'user'
        ]),
        ...mapGetters([
            'getSingleItem'
        ])
    },
    data() {
        return {
            contactSupport: {
                isModalOpened: false
            },
            isAddFlashingOpen: false,
            isAddUserOpen: false,
            isLoading: false,
            itemData: null,
            actionName: '',
            genericMessage: {
                title: '',
                message: '',
                isOpen: false
            },
        }
    },
    methods: {
        toggleSupport() {
            this.contactSupport.isModalOpened = !this.contactSupport.isModalOpened
        },
        addFlashing() {
            this.actionName = 'add'
            this.itemData = {
                name: null,
                sku: null,
                baseInWidth: null, 
                baseInLength: null, 
                baseMmWidth: null, 
                baseMmLength: null, 
                totalHeightIn: null,
                totalHeightMm: null,
                minPipeSizeIn: null,
                maxPipeSizeIn: null,
                minPipeSizeMm: null,
                maxPipeSizeMm: null,
                isRound: null,
                isSquare: null,
                isStandard: null,
                isRetrofit: null,
                isEpdm: null,
                isSilicone: null,
                isNonMetal: null,
                isMetal: null,
                roofPitch: null,
                imageUrl: null,
                epdmColors: null,
                siliconeColors: null
            }
            this.isAddFlashingOpen = !this.isAddFlashingOpen
        },
        toggleLoading() {
            this.isLoading = !this.isLoading
        },
        editFlashing() {
            this.actionName = 'update'
            this.itemData = this.getSingleItem
            this.isAddFlashingOpen = !this.isAddFlashingOpen
        },
        filterResults(method) {
            this.$emit('filterResults', method)
        },
        returnSearch(text) {
            this.$emit('returnSearch', text)
        },
        addUser() {
            this.actionName = 'add'
            this.itemData = {
                name: null,
                email: null,
                role: ''
            }
            this.isAddUserOpen = !this.isAddUserOpen
        },
        editUser() {
            this.actionName = 'update'
            this.itemData = this.getSingleItem
            this.isAddUserOpen = !this.isAddUserOpen
        },
        showErrorMessage(message) {
            this.genericMessage.title = 'Error'
            this.genericMessage.message = message
            this.genericMessage.isOpen = true
            this.toggleLoading()
        },
        closeGenericMessage() {
            this.genericMessage.isOpen = false
            this.genericMessage.title = ''
            this.genericMessage.message = ''
        }
    }
}
</script>

<style>
a, a:hover, button, button:hover, li, li:hover {
    -webkit-transition: background-color .5s ease-out;
    -moz-transition: background-color .5s ease-out;
    -o-transition: background-color .5s ease-out;
    transition: background-color .5s ease-out;
}
</style>

<style scoped>
section {
    height: 100%;
    display: grid;
    grid-template-columns: 200px calc(100vw - 200px);
}

/* Sidebar */

.sidebar {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* width: 200px; */
	background: #333;
	/* height: 100vh; */
	z-index: 1;
	padding: 10px;
    grid-column: 1 / 2;
}
.company-logo {
    margin: 20px 0 20px;
}
.sidebar-menu {
    display: inherit;
    flex-direction: inherit;
    flex: 1 0 auto;
    width: 100%;
}
.sidebar-menu ul {
    margin: 0;
    padding: 0;
    flex-grow: 1;
}
.sidebar-menu ul li {
    list-style: none;
}
.sidebar-menu ul li a {
    opacity: 0.8;
    font-size: 14px;
    font-weight: normal;
    display: block;
    font-style: normal;
    font-stretch: normal;
    line-height: 14px;
    letter-spacing: normal;
    padding: 15px;
    color: #fbfbfb;
    width: 180px;
    height: 40px;
    text-decoration: none;
}
.sidebar-menu ul li a:hover, .sidebar-menu ul li.active a {
    background-color: #ffd54f;
    font-size: 14px;
    font-weight: 900;
    font-style: normal;
    color: #333333;
    text-align: left;
    display: block;
    text-decoration: none;
    opacity: 1;
    font-family: 'Avenir-Medium';
}

#support-button {
    margin: 0 30px 30px 30px;
    height: 32px;
    border: 1px solid #ffd51e;
    background-color: unset;
    font-family: 'Avenir-Light';
    font-size: 14px;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #ffffff;
    padding: 0;
    cursor: pointer;
}

/* Dashboard */

.dashboard-main {
    grid-column: 2 / 3;
    display: grid;
    grid-template-rows: 86px calc(100vh - 86px);
    grid-template-columns: 100%;
}

@media screen and (max-width: 768px) {
    section {
        grid-template-columns: 100%;
        grid-template-rows: 60px auto;
    }
    .sidebar {
        grid-row: 1 / 2;
        flex-direction: row;
    }
    .company-logo {
        margin: 0;
        padding: 4px 0;
    }
    .sidebar-menu {
        width: unset;
        padding-left: 20px;
    }
    .sidebar-menu ul {
        display: inherit;
    }
    .sidebar-menu ul li a {
        width: 10px;
    }
    #support-button {
        margin: 0;
    }
    .dashboard-main {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        grid-template-rows: 105px calc(100vh - 105px);
    }
}
</style>