export const setCookie = (cookieName, cookieValue, expDays) => {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + date.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

export const setUserCookies = (userDetail, shouldRememberLoginDetails) => {
    let docDetail = {
        email: userDetail.email, 
        name: userDetail.name, 
        role: userDetail.role, 
        uid: userDetail.uid
    }
    setCookie('USERDETAIL', JSON.stringify(docDetail))
    if (shouldRememberLoginDetails) {
        setCookie('RPCEMAIL', userDetail.email, 14)
        setCookie('RPCLOGIN', true, 14)
    } else {
        setCookie('RPCEMAIL', '', 0)
        setCookie('RPCLOGIN', '', 0)
    }
}

export const getCookie = cookieName => {
    let name = cookieName + "=";
    let cookiesArr = document.cookie.split('; ');
    let index = cookiesArr.findIndex(cookie => cookie.includes(name))
    return index !== -1 ? cookiesArr[index].replace(name, '')  : ''
}

// Returns a LocaleDate and LocaleTime if timestamp is not null, otherwise returns an empty string.
export const getDate = timestamp => !timestamp ? '' : `${timestamp.toDate().toLocaleDateString()} ${timestamp.toDate().toLocaleTimeString()}`