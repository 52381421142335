<template>
    <tr :class="index % 2 === 0 ? 'odd' : 'even'" :id="userRow.uid">
        <td :class="{'fixed-col users-item users-name':true, 'odd':index % 2 === 0, 'even':index % 2 !== 0}">
            <label class="input-field">
                <input type="checkbox" :value="userRow.uid" v-model="currentlySelectedFlashings" @click="clickSelect">
                <span class="checkmark"></span>
                <span>{{userRow.name}}</span>
            </label>
        </td>
        <td class="users-item users-email">{{userRow.email}}</td>
        <td class="users-item users-login">{{userRow.lastLogin}}</td>
        <td class="users-item users-admin">
            <span :class="`round ${userRow.role === 'admin' ? 'round-true' : 'round-false'}`"></span>
        </td>
        <td class="users-item users-writer">
            <span :class="`round ${userRow.role === 'writer' ? 'round-true' : 'round-false'}`"></span>
        </td>
        <td class="users-item users-reader">
            <span :class="`round ${userRow.role === 'reader' ? 'round-true' : 'round-false'}`"></span>
        </td>
    </tr>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
    name: 'TableRowUsers',
    props: ['index', 'userRow'],
    computed: {
        ...mapState(['user', 'currentlySelectedFlashings', 'displayData'])
    },
    data() {
        return {
            selectedFlashings: []
        }
    },
    methods: {
        ...mapActions(['selectFlashings']),
        clickSelect(e) {
            const {value, checked} = e.target
            this.$emit('clickSelect', value, checked)
        }
    }
}
</script>

<style>

</style>