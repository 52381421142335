<template>
  <div class="table-wrapper">
    <div class="table-nav" v-if="!isFetchingFlashings && tableData.length">
      <div class="table-actions" v-if="this.$route.path === '/flashings'">
        <button class="table-button" @click="printData">Print</button>
        <button class="table-button" @click="excelData">Excel</button>
      </div>
      <div class="table-sorting">
        <p>
          Viewing <strong>{{ getCount }}</strong> of <strong>{{ count }}</strong
          ><span v-if="count !== totalOfItems">
            (from <strong>{{ totalOfItems }}</strong> entries)</span
          >
        </p>
        <button
          class="table-button previous"
          @click="tablePrevious"
          :disabled="tableIndex === 0"
        >
          <span></span>
        </button>
        <button
          class="table-button next"
          @click="tableNext"
          :disabled="tableIndex === tableData.length - 1"
        >
          <span></span>
        </button>
      </div>
    </div>
    <div class="table-data" v-if="!isFetchingFlashings && tableData.length">
      <table class="display-table">
        <thead :class="this.$route.path === '/users' ? 'users-thead' : ''">
          <tr v-if="this.$route.path === '/flashings'">
            <th class="fixed-col table-item-name">
              <label class="input-field">
                <input
                  type="checkbox"
                  v-model="selectAll"
                  @click="selectAllFlashings"
                />
                <span class="checkmark"></span>
                <span>Name</span>
              </label>
            </th>
            <th style="width: 120px; min-width: 120px">SKU</th>
            <th style="width: 155px; min-width: 150px">Min. pipe size (in)</th>
            <th style="width: 150px; min-width: 150px">Max. pipe size (in)</th>
            <th style="width: 150px; min-width: 150px">Min. pipe size (mm)</th>
            <th style="width: 155px; min-width: 150px">Max. pipe size (mm)</th>
            <th style="width: 63px; min-width: 63px">Base Width (in)</th>
            <th style="width: 63px; min-width: 63px">Base Length (in)</th>
            <th style="width: 68px; min-width: 68px">Base Width (mm)</th>
            <th style="width: 68px; min-width: 68px">Base Length (mm)</th>
            <th style="width: 70px; min-width: 70px">Height (in)</th>
            <th style="width: 80px; min-width: 80px">Height (mm)</th>
            <th style="width: 65px; min-width: 65px">Roof Pitch</th>
            <th>Round</th>
            <th>Square</th>
            <th>Standard</th>
            <th>Retrofit</th>
            <th>EPDM</th>
            <th>Silicone</th>
            <th style="width: 70px; min-width: 70px">Non-metal</th>
            <th>Metal</th>
            <th style="min-width: 155px; max-width: 200px">
              Available silicone colors
            </th>
            <th style="min-width: 145px; max-width: 200px">
              Available EPDM colors
            </th>
            <th v-if="user.role === 'superadmin'">Draft</th>
            <th
              v-if="user.role === 'superadmin'"
              style="min-width: 155px; max-width: 200px"
            >
              Image Url
            </th>
            <th
              v-if="user.role === 'superadmin'"
              style="min-width: 155px; max-width: 200px"
            >
              Created at
            </th>
            <th
              v-if="user.role === 'superadmin'"
              style="min-width: 155px; max-width: 200px"
            >
              Created by
            </th>
            <th
              v-if="user.role === 'superadmin'"
              style="min-width: 155px; max-width: 200px"
            >
              Updated at
            </th>
            <th
              v-if="user.role === 'superadmin'"
              style="min-width: 155px; max-width: 200px"
            >
              Updated by
            </th>
            <th
              v-if="user.role === 'superadmin'"
              style="min-width: 155px; max-width: 200px"
            >
              UID
            </th>
          </tr>
          <tr v-else-if="this.$route.path === '/users'">
            <th class="fixed-col users-item users-name">
              <label class="input-field">
                <input
                  type="checkbox"
                  v-model="selectAll"
                  @click="selectAllFlashings"
                />
                <span class="checkmark"></span>
                <span>Name</span>
              </label>
            </th>
            <th class="users-item users-email">E-mail</th>
            <th class="users-item users-login">Last login</th>
            <th class="users-item users-admin">Admin</th>
            <th class="users-item users-writer">Writer</th>
            <th class="users-item users-reader">Reader</th>
          </tr>
        </thead>
        <tbody v-if="this.$route.path === '/flashings'">
          <TableRowFlashings
            v-for="(flashing, i) in tableData[tableIndex]"
            :key="flashing.uid"
            :flashing="flashing"
            :index="i"
            @clickSelect="selectFlashing"
          />
        </tbody>
        <tbody v-else-if="this.$route.path === '/users'" class="users-tbody">
          <TableRowUsers
            v-for="(user, i) in tableData[tableIndex]"
            :key="user.uid"
            :userRow="user"
            :index="i"
            @clickSelect="selectFlashing"
          />
        </tbody>
      </table>
      <!-- Show texto -->
      <div class="bottom-text" v-if="this.$route.path === '/flashings'">
      <div>
          <span>Updated on {{ lastUpdatedBy.date }} by {{ lastUpdatedBy.userName }}</span>
        </div>
        <div>
          <span>*</span
          ><span id="showTextFilter">Showing {{ showing }} flashings.</span>
        </div>
        <div>
          <span>*</span
          ><span
            >Incomplete flashings (with missing data) are not displayed on the
            Roof Pitch Calculator applications.</span
          >
        </div>
      </div>
    </div>
    <div class="table-no-data" v-if="!isFetchingFlashings && !tableData.length">
      <p class="no-data">No Record Found</p>
    </div>
  </div>
</template>

<script>
import TableRowFlashings from "./TableRowFlashings";
import TableRowUsers from "./TableRowUsers";
import { mapState, mapActions } from "vuex";
import printJS from "print-js";
import XLSX from "xlsx";

export default {
  name: "Table",
  components: {
    TableRowFlashings,
    TableRowUsers,
  },
  props: ["tableData", "showing", "count"],
  computed: {
    ...mapState([
      "user",
      "currentlySelectedFlashings",
      "displayData",
      "totalOfItems",
      "isFetchingFlashings",
      "lastUpdatedBy"
    ]),
    getCount() {
      let num1 = 1 + 20 * this.tableIndex;
      let num2 =
        this.tableIndex === this.tableData.length - 1
          ? this.count
          : 20 * (this.tableIndex + 1);
      return `${num1}-${num2}`;
    },
  },
  data() {
    return {
      selectAll: false,
      selectedFlashings: [],
      tableIndex: 0,
    };
  },
  methods: {
    ...mapActions(["selectFlashings"]),
    tablePrevious() {
      if (this.tableIndex === 0) return;
      this.tableIndex--;
    },
    tableNext() {
      if (this.tableIndex === this.tableData.length - 1) return;
      this.tableIndex++;
    },
    selectAllFlashings() {
      this.selectAll = !this.selectAll;
      this.selectedFlashings = [];
      if (this.selectAll) {
        this.tableData.forEach((flashingPage) => {
          flashingPage.forEach((flashing) => {
            this.selectedFlashings.push(flashing.uid);
          });
        });
      }
      this.selectFlashings(this.selectedFlashings);
    },
    selectFlashing(value, checked) {
      if (checked) {
        this.selectedFlashings.push(value);
      } else {
        let index = this.selectedFlashings.indexOf(value);
        this.selectedFlashings.splice(index, 1);
      }
      this.selectFlashings(this.selectedFlashings);
      if (this.selectedFlashings.length !== this.displayData.length) {
        this.selectAll = false;
      } else {
        this.selectAll = true;
      }
    },
    printData() {
      const propsArr = [
        { field: "name", displayName: "Name" },
        { field: "sku", displayName: "SKU" },
        { field: "minPipeSizeIn", displayName: "Min. pipe size (in)" },
        { field: "maxPipeSizeIn", displayName: "Max. pipe size (in)" },
        { field: "minPipeSizeMm", displayName: "Min. pipe size (mm)" },
        { field: "maxPipeSizeMm", displayName: "Max. pipe size (mm)" },
        { field: "baseInWidth", displayName: "Base Width (in)" },
        { field: "baseInLength", displayName: "Base Length (in)" },
        { field: "baseMmWidth", displayName: "Base Width (mm)" },
        { field: "baseMmLength", displayName: "Base Length (mm)" },
        { field: "totalHeightIn", displayName: "Height (in)" },
        { field: "totalHeightMm", displayName: "Height (mm)" },
        { field: "roofPitch", displayName: "Roof Pitch" },
        { field: "isRound", displayName: "Round" },
        { field: "isSquare", displayName: "Square" },
        { field: "isStandard", displayName: "Standard" },
        { field: "isRetrofit", displayName: "Retrofit" },
        { field: "isEpdm", displayName: "EPDM" },
        { field: "isSilicone", displayName: "Silicone" },
        { field: "isNonMetal", displayName: "Non-metal" },
        { field: "isMetal", displayName: "Metal" },
        { field: "siliconeColors", displayName: "Available silicone colors" },
        { field: "epdmColors", displayName: "Available EPDM colors" },
      ];
      if (this.user.role === "superadmin") {
        propsArr.push({ field: "isDraft", displayName: "Draft" });
        propsArr.push({ field: "imageUrl", displayName: "Image URL" });
        propsArr.push({ field: "createdAt", displayName: "Created at" });
        propsArr.push({ field: "createdBy", displayName: "Created by" });
        propsArr.push({ field: "updatedAt", displayName: "Updated at" });
        propsArr.push({ field: "updatedBy", displayName: "Updated by" });
        propsArr.push({ field: "uid", displayName: "UID" });
      }
      printJS({
        printable: this.displayData,
        properties: propsArr,
        type: "json",
        onPrintDialogClose() {
          const node = document.getElementById("printJS");
          node.parentElement.removeChild(node);
        },
      });
    },
    excelData() {
      const xlsxData = [];
      const keys = [
        "name",
        "sku",
        "minPipeSizeIn",
        "maxPipeSizeIn",
        "minPipeSizeMm",
        "maxPipeSizeMm",
        "baseInWidth",
        "baseInLength",
        "baseMmWidth",
        "baseMmLength",
        "totalHeightIn",
        "totalHeightMm",
        "roofPitch",
        "isRound",
        "isSquare",
        "isStandard",
        "isRetrofit",
        "isEpdm",
        "isSilicone",
        "isNonMetal",
        "isMetal",
        "siliconeColors",
        "epdmColors",
        "isDraft",
        "imageUrl",
        "createdAt",
        "createdBy",
        "updatedAt",
        "updatedBy",
        "uid",
      ];

      this.displayData.forEach((item) => {
        let obj = {};
        let objKeys = keys;
        if (this.user.role !== "superadmin")
          objKeys = keys.filter(
            (key) =>
              key !== "isDraft" &&
              key !== "imageUrl" &&
              key !== "createdAt" &&
              key !== "createdBy" &&
              key !== "updatedAt" &&
              key !== "updatedBy" &&
              key !== "uid"
          );
        objKeys.forEach((key) =>
          typeof item[key] === "boolean"
            ? (obj[key] = String(item[key]))
            : (obj[key] = item[key])
        );
        xlsxData.push(obj);
      });
      let dataExport = XLSX.utils.json_to_sheet(xlsxData);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataExport, "test");
      XLSX.writeFile(wb, "Admin Console.xlsx");
    },
  },
  created() {
    this.selectFlashings([]);
  },
  watch: {
    showing() {
      this.tableIndex = 0;
    },
    tableData() {
      this.tableIndex = 0;
    },
  },
};
</script>

<style>
.no-data {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}
.table-wrapper {
  grid-row: 2 / 3;
  background-color: #fbfbfb;
  padding: 6px 42px 46px;
  display: flex;
  flex-direction: column;
}
.table-nav {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.table-button {
  border-radius: 2px;
  border: 1px solid #c6cbd4;
  background: none;
  cursor: pointer;
}
.table-button:disabled {
  cursor: not-allowed;
}
.table-actions button {
  height: 32px;
  padding: 0 10px;
  font-family: "Avenir-Light";
}
.table-actions button:nth-child(1) {
  margin-right: 5px;
}
.table-sorting {
  display: inherit;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}
.table-sorting p {
  margin: 0 10px 0 0;
  font-size: 14px;
}
.table-sorting button {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table-sorting button span {
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.table-sorting .previous span {
  border-right: 3px solid;
}
.table-sorting .next span {
  border-left: 3px solid;
}
.table-data {
  display: inherit;
  flex-direction: inherit;
  flex: 1;
  min-height: calc(100% - 42px);
}
.display-table {
  display: block;
  overflow: auto;
  /* height: 300px; */
  border-collapse: collapse;
  font-size: 12px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
}
.display-table thead tr th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fbfbfb;
  color: rgba(33, 33, 33, 0.5);
}
.users-thead,
.users-thead tr,
.users-tbody,
.users-tbody tr {
  display: inline-block;
  width: 100%;
}
.users-thead,
.users-thead tr {
  position: sticky;
  top: 0;
  z-index: 2;
}
.users-item {
  display: inline-flex;
  align-items: center;
}
.users-name {
  width: 28%;
}
.users-email {
  width: 26.5%;
}
.users-login {
  width: 20.5%;
}
.users-admin {
  width: 8%;
}
.users-writer {
  width: 8%;
}
.users-reader {
  width: 9%;
}
.display-table th,
.display-table td {
  height: 54px;
  padding: 10px;
  text-align: left;
}
.fixed-col {
  position: sticky;
  left: 0;
  z-index: 1;
}
.display-table thead tr .table-item-name {
  z-index: 3;
}
.table-item-name {
  width: 270px;
  min-width: 270px;
}
.table-item-name label,
.users-name label {
  margin-left: 10px;
}

.display-table .input-field {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.display-table td .input-field {
  font-weight: normal;
}
.display-table .input-field input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.display-table .input-field .checkmark {
  content: "";
  background: #bdbdbd;
  width: 14px;
  height: 14px;
  opacity: 1;
  position: absolute;
  cursor: pointer;
  left: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}
.display-table .input-field input:checked ~ .checkmark {
  background: #ffd54f;
  opacity: 1;
  position: absolute;
}
.display-table .input-field span.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.display-table .input-field input:checked ~ .checkmark:after {
  display: block;
}
.display-table .checkmark:after {
  left: 5px;
  top: 1px;
  width: 2px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.display-table .input-field label {
  display: flex;
  margin-bottom: 0;
  font-weight: unset;
}

.display-table .input-field input:checked ~ .checkmark-in {
  display: inline-block;
  width: 13px;
  height: 13px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  z-index: 1;
  position: absolute;
  top: 1px;
}

.display-table .input-field input:checked ~ .checkmark-in .checkmark_stem {
  position: absolute;
  width: 2px;
  height: 10px;
  left: 8px;
  background-color: #fff;
  top: 2px;
}

.display-table .input-field input:checked ~ .checkmark-in .checkmark_kick {
  position: absolute;
  width: 5px;
  height: 2px;
  background-color: #fff;
  left: 4px;
  top: 10px;
}
.odd {
  background-color: #f9f9f9;
}
.even {
  background-color: #eeeff1;
}
.round {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 100%;
}
.round-true {
  background-color: #ffd54f;
}
.round-false {
  background-color: #bdbdbd;
}

.bottom-text {
  margin-top: 30px;
  line-height: 0;
  display: flex;
  flex-direction: column;
}
.bottom-text div:nth-child(1) {
  margin-bottom: 5px;
}
.bottom-text div:nth-child(1) span {
  font-style: italic;
}
.bottom-text span {
  font-family: "Avenir-Light";
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 5px;
}
#printJS {
  display: none;
}

@media screen and (max-width: 768px) {
  .table-wrapper {
    padding: 15px;
  }
  .table-data {
    min-height: calc(100% - 197px);
  }
}
</style>
