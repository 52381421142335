<template>
    <ModalTemplate :title="userModalName" :trigger="trigger" :toggle="toggle">
        <div id="user-form">
            <div class="requisite">
                <label for="user_name" class="requisite-name">Name</label>
                <input id="user_name" type="text" placeholder="" v-model.trim="userData.name">
            </div>
            <div class="requisite">
                <label for="user_email" class="requisite-name">Email</label>
                <input id="user_email" type="email" placeholder="" v-model.trim="userData.email" @keyup="checkEmail" :disabled="action === 'update' ? true : false" />
            </div>
            <div class="requisite">
                <label for="user_pass" class="requisite-name">Password</label>
                <input id="user_pass" :type="action === 'add' ? 'text' : 'password'" :value="randomPass" disabled>
            </div>
            <div class="role-group">
                <div>
                <span class="requisite-name">Role</span>
                <ul class="role-group-list">
                    <label for="role_admin"><input id="role_admin" type="radio" name="role" :checked="userData.role === 'admin'" value="admin" @change="setRole"><span class="round-checkmark"></span><span class="role-name">Admin</span></label>
                    <label for="role_writer"><input id="role_writer" type="radio" name="role" :checked="userData.role === 'writer'" value="writer" @change="setRole"><span class="round-checkmark"></span><span class="role-name">Writer</span></label>
                    <label for="role_reader"><input id="role_reader" type="radio" name="role" :checked="userData.role === 'reader'" value="reader" @change="setRole"><span class="round-checkmark"></span><span class="role-name">Reader</span></label>
                </ul>
            </div>
                <p id="role-warning" v-if="changedRole">Please note that changing the users role will only take effect upon their next login.</p>
            </div>
            <button type="button" v-if="action === 'add'" @click="addUser" :disabled="shouldDisableSaveButton">Save</button>
            <button type="button" v-else-if="action === 'update'" @click="updateUser" :disabled="shouldDisableUpdateButton">Update</button>
            <div class="pop-up-bottom-text">
                <p>Admin role = has the ability to manage all the flashings and users.</p>
                <p>Writer role = has the ability to manage all the flashings only.</p>
                <p>Reader role = has the ability to see all the flashings only.</p>
            </div>
        </div>
    </ModalTemplate>
</template>

<script>
import ModalTemplate from '../Modals/ModalTemplate'
import firebase from 'firebase'
import router from '../../router'

export default {
    name: 'AddUser',
    router,
    components: {
        ModalTemplate
    },
    props: ['trigger', 'toggle', 'data', 'action'],
    computed: {
        shouldDisableSaveButton() {
            return !(this.userData.name && this.isEmailValid && (this.userData.role !== ''))
        },
        shouldDisableUpdateButton() {
            return !(this.userData.name.length && ((this.userData.name !== this.previousData.name) || this.changedRole))
        },
        userModalName() {
            return this.action === 'add' ? "Add User" : "Update User" 
        }
    },
    data() {
        return {
            userData: {
                name: null,
                email: null,
                role: '',
            },
            randomPass: '',
            previousData: {},
            editingUser: null,
            isEmailValid: null,
            changedRole: false
        }
    },
    methods: {
        checkEmail(e) { // same as ForgotPassword.vue
            let regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/gi
            this.isEmailValid = regex.test(e.target.value)
        },
        passwordShuffle() {
            return Math.floor(100000 + Math.random() * 900000)
        },
        setRole(e) {
            this.userData.role = e.target.value
            if (this.action === 'update') this.changedRole = (this.userData.role !== this.previousData.role)
        },
        async addUser() {
            this.$emit('processing')
            try {
                let firebaseAuthenticationUser = { email: this.userData.email, password: this.randomPass }
                let firebaseFirestoreUser = { name: this.userData.name, email: this.userData.email, role: this.userData.role, lastLogin: null, isFirstAccess: true }
                const createUserOnFirebaseAuthentication = firebase.functions().httpsCallable('createUserOnFirebaseAuthentication')
                const result = await createUserOnFirebaseAuthentication(firebaseAuthenticationUser)
                firebaseFirestoreUser.uid = result.data.uid
                await this.addUserToCollection(firebaseFirestoreUser)
                router.go()
            } catch (error) {
                this.$emit('error', error.message)
            }
        },
        async addUserToCollection(user) {
            try {
                await firebase.firestore().collection('users').doc(user.uid).set(user)
                return true
            } catch (error) {
                this.$emit('error', error.message)
            }
        },
        async updateUser() {
            this.$emit('processing')
            let newInfo = {}
            if (this.userData.name !== this.previousData.name) newInfo.name = this.userData.name
            if (this.userData.role !== this.previousData.role) newInfo.role = this.userData.role
            try {
                await firebase.firestore().collection('users').doc(this.editingUser).update(newInfo)
                router.go()
            } catch (error) {
                this.$emit('error', error.message)
            }
        }
    },
    watch: {
        trigger() {
            if (this.trigger === true) { // this sets the data to the initial state when the modal opens
                this.userData.name = null,
                this.userData.email = null,
                this.userData.role = ''
                this.randomPass = this.action === 'add' ? this.passwordShuffle().toString() : '######'
            } else { // this clears the data when the modal closes (without saving)
                if(this.previousData) this.previousData = {}
                this.editingUser = null
                this.changedRole = false
            }
        },
        data() {
            this.userData = {...this.data}
            delete this.userData.lastLogin
            delete this.userData.uid
            if (this.action === 'update') {
                this.editingUser = this.data.uid
                this.previousData.name = this.data.name
                this.previousData.role = this.data.role
            }
        }
    }
}
</script>

<style>
#user-form input:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
.role-group {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 75px;
}
.role-group > div {
    display: inherit;
    width: 100%;
}
.role-group-list {
    width: 70%;
}
.role-group-list label {
    position: relative;
    font-size: 12px;
    margin-right: 30px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
.role-group-list input {
    display: none;
}
.role-group-list input:checked ~ .round-checkmark {
    background-color: #ffc106;
    border: 1px solid #ffc106;
}
.round-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 1px solid #cacfd7;
    border-radius: 100%;
}
.role-name {
    margin-left: 34px;
}
#role-warning {
    font-size: 12px;
    letter-spacing: normal;
    line-height: normal;
    margin: 0 0 10px;
}
#user-form button {
    background-color: #ffc106;
    height: 80px;
    width: 100%;
    border: none;
    border-radius: 0;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 5px;
    font-weight: 900;
    color: #212121;
    font-family: 'Avenir-Medium';
    transition: background-color 500ms, opacity 500ms;
    cursor: pointer;
}
#user-form button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}
.pop-up-bottom-text {
    display: block;
    width: 100%;
    margin-top: 40px;
    font-size: 12px;
}
.pop-up-bottom-text p {
    margin: 0;
    line-height: normal;
    letter-spacing: normal;
}
</style>