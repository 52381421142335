<template>
  <div class="header">
        <ManageAccount :trigger="isManageAccountOpen" :toggle="openManageAccount" @success="openManageAccount" @error="errorOnRequest" @updatePass="showUpdatePassword" @deleteAcc="showDeleteAccount" />
        <UpdatePassword :trigger="isUpdatePasswordOpen" :toggle="hideUpdatePassword" @processing="toggleLoading" @success="successOnUpdate" @error="errorOnRequest" />
        <DeleteAccount :trigger="isDeleteAccountOpen" :toggle="hideDeleteAccount" @processing="toggleLoading" @error="errorOnRequest" />
        <GenericMessage :trigger="genericMessage.isOpen" :title="genericMessage.title" :message="genericMessage.message" @closeMessage='closeGenericMessage' />
        <DeleteItem :trigger="isDeleteItemOpen" :toggle="toggleDeleteItem" @processing="toggleLoading" @error="errorOnRequest" />
        <Loading :trigger="isLoading" />
        <div class="actions-wrapper">
            <h2>{{ pageTitle }}</h2>
            <div v-if="!currentlySelectedFlashings.length">
                <button @click="openAddModal" class="add-flashing" v-if="user.role !== 'reader'">Add +</button>
                <div class="filter" v-if="this.$route.path === '/flashings'">
                    <button type="button" class="filter-button" @click="toggleFilter">
                        <span v-if="filter.filterBy === 'complete'">Show complete flashings</span>
                        <span v-else-if="filter.filterBy === 'incomplete'">Show incomplete flashings</span>
                        <span v-else>Show all flashings</span>
                        <span class="caret"></span>
                    </button>
                    <ul class="filter-menu" role="menu" v-if="filter.isOpen">
                        <li @click="filterAll" v-if="filter.filterBy !== 'all'"><span>Show all flashings</span></li>
                        <li @click="filterComplete" v-if="filter.filterBy !== 'complete'"><span>Show complete flashings</span></li>
                        <li @click="filterIncomplete" v-if="filter.filterBy !== 'incomplete'"><span>Show incomplete flashings</span></li>
                    </ul>
                </div>
                </div>
            <div v-if="currentlySelectedFlashings.length && user.role !== 'reader'">
                <button class="delete-item" @click="toggleDeleteItem">Delete<font-awesome-icon icon="trash" /></button>
                <button class="edit-flashing" @click="openEditModal" :disabled="currentlySelectedFlashings.length > 1">Edit<font-awesome-icon icon="pencil-alt" /></button>
            </div>
        </div>
        <div class="search-and-settings">
            <div class="searchbar">					  
                <font-awesome-icon icon="search" />
                <input class="search-input" type="text" name="search" placeholder="Search" @keyup="returnSearch">
            </div>
            <button @click="toggleSettings" class="settings-button"> <span></span><span></span><span></span> </button>
            <div class="settings-box" v-if="isSettingsBoxOpen">
                <ul class="pr-admin-menu">
                    <li>
                        <span @click="openManageAccount"><font-awesome-icon icon="cog" />Manage account</span>
                    </li>
                    <li>
                        <span @click="logUserOut"><font-awesome-icon icon="sign-out-alt" />Sign out</span>
                    </li>
                </ul>
                <div class="top-nav-user-email">
                    <span id="loginMail">{{ currentUser }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '../../store'
import { mapState, mapActions } from 'vuex'
import ManageAccount from './ManageAccount'
import UpdatePassword from './UpdatePassword'
import DeleteAccount from './DeleteAccount'
import GenericMessage from '../Modals/GenericMessage'
import DeleteItem from './DeleteItem'
import Loading from '../Modals/Loading'

export default {
    name: 'Header',
    store,
    components: {
        ManageAccount,
        UpdatePassword,
        DeleteAccount,
        GenericMessage,
        DeleteItem,
        Loading
    },
    props: ['currentUser',  'pageTitle'],
    computed: {
        ...mapState(['currentlySelectedFlashings', 'user'])
    },
    data() {
        return {
            filter: {
                isOpen: false,
                filterBy: 'all'
            },
            isSettingsBoxOpen: false,
            isManageAccountOpen: false,
            isUpdatePasswordOpen: false,
            isDeleteAccountOpen: false,
            genericMessage: {
                title: '',
                message: '',
                isOpen: false
            },
            isDeleteItemOpen: false,
            isLoading: false
        }
    },
    methods: {
        ...mapActions([
            'logUserOut'
        ]),
        toggleLoading() {
            this.isLoading = !this.isLoading
        },
        toggleDeleteItem() {
            this.isDeleteItemOpen = !this.isDeleteItemOpen
        },
        toggleFilter() {
            this.filter.isOpen = !this.filter.isOpen
        },
        filterAll() {
            this.filter.filterBy = 'all'
            this.toggleFilter()
            this.$emit('filterResults', this.filter.filterBy)
        },
        filterComplete() {
            this.filter.filterBy = 'complete'
            this.toggleFilter()
            this.$emit('filterResults', this.filter.filterBy)
        },
        filterIncomplete() {
            this.filter.filterBy = 'incomplete'
            this.toggleFilter()
            this.$emit('filterResults', this.filter.filterBy)
        },
        toggleSettings() {
            this.isSettingsBoxOpen = !this.isSettingsBoxOpen
        },
        openManageAccount() {
            this.isManageAccountOpen = !this.isManageAccountOpen
        },
        openAddModal() {
            if (this.$route.path === '/flashings') this.$emit('addFlashing')
            if (this.$route.path === '/users') this.$emit('addUser')
        },
        showUpdatePassword() {
            this.isUpdatePasswordOpen = true
            this.isManageAccountOpen = false
        },
        hideUpdatePassword() {
            this.isUpdatePasswordOpen = false
        },
        successOnUpdate() {
            this.hideUpdatePassword()
            this.genericMessage.title = 'Success!'
            this.genericMessage.message = 'Your password has been successfully changed.'
            this.genericMessage.isOpen = true
            this.toggleLoading()
        },
        errorOnRequest(request, message) {
            if (request === 'deleteItem')  this.deleteItem()
            this.genericMessage.title = 'Error'
            this.genericMessage.message = message
            this.genericMessage.isOpen = true
            this.toggleLoading()
        },
        showDeleteAccount() {
            this.isDeleteAccountOpen = true
            this.isManageAccountOpen = false
        },
        hideDeleteAccount() {
            this.isDeleteAccountOpen = false
        },
        closeGenericMessage() {
            this.genericMessage.isOpen = false
            this.genericMessage.title = ''
            this.genericMessage.message = ''
        },
        openEditModal() {
            if (this.$route.path === '/flashings') this.$emit('editFlashing')
            if (this.$route.path === '/users') this.$emit('editUser')
        },
        returnSearch(e) {
            this.$emit('returnSearch', e.target.value)
        }
    }
}
</script>

<style scoped>
.header {
    padding: 27px 50px;
    display: flex;
    border-bottom: 1px solid #efefef;
}
.header h2 { 
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 32px;
    letter-spacing: normal;
    color: #333333;
    font-family: 'Avenir-Medium', sans-serif;
    margin-right: 25px;
}
.actions-wrapper button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    background-color: #ffd54f;
    border: 0;
    padding: 6px 12px;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    color: #333;
}
.actions-wrapper button:hover {
    color: #fff;
    background-color: #333;
}
.actions-wrapper button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.delete-item {
    margin-right: 10px;
}
.header ul {
    list-style: none;
}
.actions-wrapper {
    display: flex;
}
.actions-wrapper > div {
    display: flex;
}
.actions-wrapper svg {
    margin-left: 10px;
    height: 12px;
}

/* Search */
.search-input {
	padding-left: 2.375rem;
    background-color: #eeeff1;
    border-radius: 0;
    padding-left: 30px;
    border: none;
    box-shadow: none;
    height: 32px;
    width: 150px;
    line-height: normal;
	border: 0;
	outline: 0;
	line-height: 31px;
	font-size: 14px;
	margin-left: 10px;
	caret-color: #4d4d4d;
    font-family: inherit;
}
.search-input::placeholder {
    color: #999;
    opacity: 1;
}


/* Filter */
.filter {
    margin-left: 10px;
    width: 220px;
    position: relative;
    display: flex;
}
.filter-button {
    background-color: #ffd54f;
    padding: 6px 20px 6px 12px;
    width: 220px;
    border: 0;
    border-radius: 0;
    font-size: 14px;
    line-height: 1.71;
    height: 32px;
    text-align: left;
    cursor: pointer;
    font-family: inherit;
}
.filter-button:focus {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
}
.filter-menu {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    width: 100%;
    background-color: #ffd54f;
    position: absolute;
    z-index: 2;
    top: 32px;
}
.caret {
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.filter-button, .filter li {
    height: 32px;
    padding: 4px 10px;
    font-family: inherit;
    font-size: 14px;
    cursor: pointer;
    color: #333;
}
.filter li:hover {
    color: #fff;
    background-color: #333;
}

.search-and-settings {
    position: relative;
    display: flex;
    flex: 1;
    justify-content: flex-end;
}
.searchbar {
    position: relative;
}
.search-and-settings .searchbar svg {
    position: absolute;
    left: 18px;
    color: #aaa;
    top: 8px;
    opacity: 0.8;
}

/* Settings box */
.settings-box {
    width: 171px;
    min-height: 116px;
    height: auto;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 4;
}
.settings-button {
    cursor: pointer;
	width: 20px;
	height: 29px;
	background: none;
	border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.settings-button:hover {
    background-color: #fff;
}
.settings-button span {
	width: 4px;
	height: 4px;
	background: #333;
	border-radius: 100%;
    margin: 1px 0;
}
.settings-box ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 7px;
    padding-bottom: 7px;
}
.settings-box ul li span {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #333333;
    text-decoration: none;
    display: block;
    padding: 4px 12px;
    align-items: center;
    display: flex;
    cursor: pointer;
    transition: background-color 500ms ease-out;
}
.settings-box ul li span:hover {
    background-color: #ffd54f;
}
.settings-box svg {
    height: 15px;
    margin-right: 11px;
}
.top-nav-user-email {
    border-top: 2px solid #f4f4f4;
}
.top-nav-user-email span {
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    display: block;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    padding: 10px;
    word-break: break-word;
}

@media (max-width: 1024px) {
    .flashings-screen-sidebar {
		width: 100%;
		height: 60px;
		flex-direction: row;
	}
}

@media screen and (max-width: 768px) {
    .header {
        padding: 15px;
        flex-direction: column;
    }
    .header h2 {
        font-size: 18px;
        margin-right: 10px;
    }
    .actions-wrapper {
        width: 100%;
        display: flex;
    }
    .actions-wrapper > div {
        width: 100%;
    }
    .actions-wrapper button {
        font-size: 12px;
        width: unset;
    }
    .filter {
        margin-right: 20px;
        width: unset;
        flex-grow: 1;
    }
    .filter-button {
        flex-grow: 1;
    }
    .filter li {
        font-size: 12px;
    }
    .search-and-settings {
        margin-top: 10px;
    }
    .searchbar {
        width: 100%;
    }
    .search-and-settings .searchbar svg {
        left: 10px;
    }
    .search-input {
        width: 100%;
        margin-left: 0;
    }
    .settings-button {
        margin-left: 1px;
    }
}

</style>