import Vue from 'vue'
import Router from 'vue-router'
import SignIn from './views/SignIn.vue'
import Flashings from './views/Flashings.vue'
import Users from './views/Users.vue'
import store from './store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: SignIn
        },
        {
            path: '/flashings',
            component: Flashings
        },
        {
            path: '/users',
            component: Users
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
})

router.beforeEach((to, from, next) => {
    store.dispatch('getCurrentUserInfo')
    switch (to.fullPath) {
        case '/':
            if (store.state.isUserLoggedIn === true) next('/flashings')
            break
        case '/flashings':
            if (store.state.isUserLoggedIn === false) next('/')
            break;
        case '/users':
            if (store.state.isUserLoggedIn === false) {
                next('/')
            } else {
                if (store.state.user.role === 'writer' || store.state.user.role === 'reader') {
                    next('/flashings')
                }
            }
            break
        default:
            break;
    }
    next()
})

export default router