<template>
    <ModalTemplate title="Delete" :trigger="trigger" :toggle="toggle">
        <p class="delete-p">Are you sure you want to delete {{ this.flashingName }}?</p>
        <button class="delete-button" @click="deleteItems">Delete Permanently</button>
    </ModalTemplate>
</template>

<script>
import ModalTemplate from '../Modals/ModalTemplate'
import {mapState} from 'vuex'
import firebase from 'firebase'
import router from '../../router'

export default {
    name: 'DeleteItem',
    router,
    components: {
        ModalTemplate
    },
    props: ['trigger', 'toggle'],
    computed: {
        ...mapState(['currentlySelectedFlashings', 'displayData']),
        flashingName() {
            if (this.currentlySelectedFlashings.length > 1) {
                return `the selected ${this.returnTypeName}`
            } else if (this.currentlySelectedFlashings.length === 1) {
                return this.displayData.find(item => item.uid === this.currentlySelectedFlashings[0]).name
            } else {
                return ''
            }
        },
        returnTypeName() {
            return this.$route.path === '/users' ? 'users' : 'products'
        }
    },
    methods: {
        async deleteItems() {
            this.$emit('processing')
            for (var i = 0; i < this.currentlySelectedFlashings.length; i++) {
                try {
                    if (this.$route.path === '/flashings') { // this part is to delete flashings
                        let itemName = ''
                        const ref = firebase.firestore().collection('flashings').doc(this.currentlySelectedFlashings[i])
                        await ref.get().then(doc => {
                            itemName = doc.data()
                        })
                        firebase.storage().ref().child('images/flashings/' + itemName.uid).delete();
                        await ref.delete()
                    }
                    if (this.$route.path === '/users') { // this will delete users only
                        await firebase.firestore().collection('users').doc(this.currentlySelectedFlashings[i]).delete()
                    }
                } catch (error) {
                    this.$emit('error', 'deleteItem', error.message)
                    return false
                }
            }
            router.go()
        }
    }
}
</script>

<style>
.delete-p {
    margin: 0;
}
.delete-button {
    background-color: #ffc106;
    height: 80px;
    width: 100%;
    border: none;
    border-radius: 0;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 40px;
    font-weight: 900;
    font-family: 'Avenir-Medium', sans-serif;
    transition: background-color 500ms, opacity 500ms;
    cursor: pointer;
}
</style>