<template>
    <transition name="fade">
        <div class="pre-dialog" v-show="trigger">
            <div class="dialog" @click.self="toggle">
            <transition appear name="slide">
                <div class="custom-modal" v-if="trigger">
                    <button type="button" class="close" @click="toggle">&times;</button>
                    <div class="modal-body">
                        <h4 v-if="title">{{ title }}</h4>
                    <slot></slot>
                    </div>
                </div>
            </transition>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ModalTemplate',
    props: ['trigger', 'toggle', 'title'],
    methods: {
        test() {
            if (event.key === 'Escape') {
                this.toggle()
                window.removeEventListener('keyup', this.test)
            }
        }
    },
    watch: {
        trigger() {
            if (this.trigger === true) window.addEventListener('keyup', this.test)
        }
    }
}
</script>

<style>
.pre-dialog {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    overflow-x: hidden;
    overflow-y: auto;
}
.dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(176,176,176,0.8);
    padding: inherit;
    min-height: 100%;
}
.custom-modal {
	border-radius: 0;
    border: none;
    width: 100%;
    max-width: 660px;
    margin: 20px auto;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.5);
    position: relative;
    background-color: #fff;
}
.custom-modal button.close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
    cursor: pointer;
    background: none;
    border: 0;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.4;
    margin-top: -2px;
    font-size: 16px;
    font-weight: 700;
}
.generic .dialog {
    justify-content: flex-start;
}
.generic .custom-modal {
    margin-top: 30px;
    width: 100%;

}
.modal-body {
    padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
    height: 100%;
    position: relative;
}
.generic .modal-body {
    padding: 15px;
}
.generic .modal-body p {
    margin-bottom: 10px;
}
h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 0;
    color: #333;
    font-family: 'Avenir-Medium';
    letter-spacing:.5px;
    line-height: 1.1;
}
p {
    letter-spacing: 0.25px;
    line-height: 23px;
    margin-bottom: 35px;
    color: #333;
    letter-spacing: 0.25px;
    line-height: 23px;
    margin-bottom: 35px;
}

/* Transitions */

.fade-enter-active, .fade-leave-active {
    transition: opacity 500ms;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.fade-enter-to, .fade-leave {
    opacity: 1;
}

.slide-enter-active, .slide-leave-active {
    transition: transform 300ms ease-out;
}
.slide-enter, .slide-leave-to {
    transform: translateY(-25%);
}
.slide-enter-to, .slide-leave {
    transform: translateY(0);
}

@media screen and (max-width: 768px) {
    .custom-modal {
        width: 90%;
    }
    .modal-body {
        padding: 30px 20px;
    }
}
</style>