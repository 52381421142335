<template>
    <DashboardTemplate pageTitle="Users" @filterResults="useFilter" @returnSearch="searchTerm">
        <Loading :trigger="isFetchingFlashings" />
        <Table :tableData="tableData" :showing="showing" :count="tableLength" />
    </DashboardTemplate>
</template>

<script>
import router from '../router'
import DashboardTemplate from '../components/DashboardComponents/DashboardTemplate'
import {mapState, mapActions, mapGetters} from 'vuex'
import Table from '../components/DashboardComponents/Table'
import Loading from '../components/Modals/Loading'

export default {
    name: 'Users',
    router,
    components: {
        DashboardTemplate,
        Table,
        Loading
    },
    computed: {
        ...mapState([
            'rawData', 'currentlySelectedFlashings', 'isFetchingFlashings', 'displayData', 'user'
        ]),
        ...mapGetters([
            'getAllFlashings', 'searchForTerm', 'getFilteredData'
        ])
    },
    data() {
        return {
            tableData: [],
            tableLength: 0,
            showing: 'all',
            searchResult: [],
            searchWord: null
        }
    },
    methods: {
        ...mapActions(['fetchUsers']),
        useFilter(method) {
            const dataArr = this.getFilteredData(method, this.searchWord)
            this.tableData = this.divideIntoArrays(dataArr)
            this.showing = method
        },
        searchTerm(term) {
            this.searchWord = term ? term : null
            const dataArr = this.searchForTerm(this.searchWord, this.showing)
            return this.tableData = !dataArr.length ? dataArr : this.divideIntoArrays(dataArr)
        },
        divideIntoArrays(array) {
            const displayArr = []
            if (array.length) {
                this.tableLength = array.length
                displayArr[0] = []
                let arrIndex = 0
                array.forEach(item => {
                    if (displayArr[arrIndex].length === 20) {
                    arrIndex++
                    displayArr[arrIndex] = []
                    }
                    displayArr[arrIndex].push(item)
                })
            }
            return displayArr
        }
    },
    watch: {
        displayData() {
            this.tableData = this.divideIntoArrays(this.getAllFlashings)
        }
    },
    mounted() {
        this.fetchUsers()
    }
}
</script>

<style>

</style>