<template>
    <ModalTemplate :trigger="trigger" :toggle="close" :title="title" class="generic">
        <p>{{ message }}</p>
    </ModalTemplate>
</template>

<script>
import ModalTemplate from './ModalTemplate'

export default {
    name: 'GenericMessage',
    props: ['trigger', 'toggle', 'title', 'message'],
    components: {
        ModalTemplate
    },
    data() {
        return {
            className: 'generic'
        }
    },
    methods: {
        close() {
            this.$emit('closeMessage')
        }
    }
}
</script>

<style>

</style>