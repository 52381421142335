<template>
<section class="login">
    <!-- Modals -->
    <ForgotPassword :trigger="isForgotPassModalOpen" :toggle="toggleForgotPassword" @processing="toggleLoading" @success="successOnAction" @error="errorOnAction" />
    <PasswordReset :trigger="isPasswordRecoveryModalOpen" :toggle="togglePasswordRecovery" @processing="toggleLoading" @success="successOnAction" @error="errorOnAction" />
    <CreateNewPassword :email="email" :rememberMe="rememberMe" :trigger="isSetNewPasswordOpen" :toggle="toggleSetNewPassword" @processing="toggleLoading" />
    <GenericMessage :trigger="genericMessage.isOpen" :title="genericMessage.title" :message="genericMessage.message" @closeMessage='closeGenericMessage' />
    <Loading :trigger="isLoading" />
    <!-- Component itself -->
    <h1>Sign In</h1>
    <form @submit="login" autocomplete="off">
        <input type="email" class="input-box" v-model.trim="email" placeholder="E-mail">
        <input type="password" class="input-box" v-model.trim="password" placeholder="Password">
        <div class="checkbox">
            <label>
                <input type="checkbox" v-model="rememberMe">
                <span class="checkmark">
                    <span class="checkmark-in">
                        <div class="checkmark_stem"></div>
                        <div class="checkmark_kick"></div>
                    </span>
                </span>
                <span class="text-remember">Remember me</span>
            </label>
            <span class="forgot" @click="toggleForgotPassword">Forgot password?</span>
        </div>
        <button type="submit" :disabled="email && password ? false : true">Login</button>
    </form>
    <p>Admin Console - V{{ version }}</p>
</section>
</template>

<script>
import store from '../store'
import router from '../router'
import { mapState, mapActions } from 'vuex';
import {getCookie} from '../utils'
import ForgotPassword from '../components/SignInComponents/ForgotPassword'
import GenericMessage from '../components/Modals/GenericMessage'
import PasswordReset from '../components/SignInComponents/PasswordReset'
import CreateNewPassword from '../components/SignInComponents/CreateNewPassword'
import Loading from '../components/Modals/Loading'

export default {
    name: 'SignIn',
    router,
    store,
    components: {
        ForgotPassword,
        GenericMessage,
        PasswordReset,
        CreateNewPassword,
        Loading
    },
    computed: {
        ...mapState([
            'isUserLoggedIn',
            'isFirstAccess',
            'loginError'
        ])
    },
    data() {
        return {
            email: '',
            password: '',
            rememberMe: false,
            isForgotPassModalOpen: false,
            isPasswordRecoveryModalOpen: false,
            genericMessage: {
                title: '',
                message: '',
                isOpen: false
            },
            isSetNewPasswordOpen: false,
            isLoading: false,
            version: process.env.VUE_APP_VERSION
        }
    },
    created() {
        this.email = getCookie('RPCEMAIL'),
        this.rememberMe = Boolean(getCookie('RPCLOGIN'))
        this.isPasswordRecoveryModalOpen = this.$route.query.mode === 'resetPassword' ? true : false
    },
    methods: {
        ...mapActions(['logUserIn', 'resetFirstAccess']),
        toggleLoading() {
            this.isLoading = !this.isLoading
        },
        login(e) {
            e.preventDefault()
            this.toggleLoading()
            this.logUserIn([this.email, this.password, this.rememberMe])
        },
        toggleForgotPassword() {
            this.isForgotPassModalOpen = !this.isForgotPassModalOpen
        },
        toggleForgotMessage(input) {
            if (input === 'success') this.recoverPassword.hadSuccess = !this.recoverPassword.hadSuccess
            if (input === 'error') this.recoverPassword.hadFailed = !this.recoverPassword.hadFailed
        },
        passwordRecoveryFails(message) {
            this.toggleForgotPassword()
            this.toggleForgotMessage('error')
            this.recoverPassword.errorMessage = message
        },
        passwordRecoverySucceeds() {
            this.toggleForgotPassword()
            this.toggleForgotMessage('success')
        },
        togglePasswordRecovery() {
            this.isPasswordRecoveryModalOpen = !this.isPasswordRecoveryModalOpen
        },
        toggleResetMessage(input) {
            if (input === 'success') this.resetPassword.hadSuccess = !this.resetPassword.hadSuccess
            if (input === 'error') this.resetPassword.hadFailed = !this.resetPassword.hadFailed
        },
        passwordResetSucceeds() {
            this.isPasswordRecoveryModalOpen = false
            this.toggleResetMessage('success')
        },
        passwordResetFails(message) {
            this.togglePasswordRecovery()
            this.toggleResetMessage('error')
            this.recoverPassword.errorMessage = message
        },
        toggleSetNewPassword() {
            this.isSetNewPasswordOpen = !this.isSetNewPasswordOpen
        },

        successOnAction(action) {
            switch (action) {
                case 'forgot':
                    this.genericMessage.title = 'Recovery e-mail sent'
                    this.genericMessage.message = 'A recovery e-mail was sent to your e-mail address. Please check it out.'
                    this.toggleForgotPassword()
                    break
                case 'reset':
                    this.genericMessage.title = 'Password recovered'
                    this.genericMessage.message = 'Please procceed with login.'
                    this.togglePasswordRecovery()
                    break
                default:
                    break
            }
            this.toggleLoading()
            this.genericMessage.isOpen = true
        },
        errorOnAction(action, message) {
            if (action === 'forgot') {
                this.genericMessage.title = 'Unable to send the recovery e-mail'
                this.genericMessage.message = 'There is no user with the informed e-mail address. Please make sure to inform a valid one.'
            } else if (action === 'reset') {
                this.genericMessage.title = 'Error'
                this.genericMessage.message = message
                this.togglePasswordRecovery()
            } else {
                this.genericMessage.title = 'Error'
                this.genericMessage.message = action // user on login
            }
            this.toggleLoading()
            this.genericMessage.isOpen = true
        },
        closeGenericMessage() {
            this.genericMessage.isOpen = false
            this.genericMessage.title = ''
            this.genericMessage.message = ''
        }
    },
    watch: {
        'loginError': function() { // show error modal in case the login fails
            this.errorOnAction(this.loginError)
        },
        'isFirstAccess': function(itIs) { // controls CreateNewPassword modal
            if (itIs) {
                this.isSetNewPasswordOpen = true 
                this.toggleLoading()
                this.resetFirstAccess()
            }
        }
    }
}
</script>

<style scoped>
.login {
	background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 0 15px;
}
.login h1 {
    text-transform: uppercase;
    margin: 70px 0 100px;
    color: #333333;
    font-size: 50px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Avenir-Medium', sans-serif;
}
.login form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    width: 512px;
    max-height: 420px;
    min-height: 360px;
    font-family: 'Avenir-Book', sans-serif;
}
.input-box {
    background: #bdbdbd;
	border: none;
	border-radius: 0;
	height: 80px;
	font-size: 20px;
	color: #333;
	padding-left: 30px;
	padding-right: 30px;
	font-family: 'Avenir-Medium', sans-serif;
	font-weight: normal;
}
.input-box::placeholder {
    font-family: 'Avenir-Book', sans-serif;
    opacity: 1;
    color: #333;
}
.input-box:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgb(255, 226, 132);
}
.checkbox {
    display: flex;
    justify-content: space-between;
}
.checkbox label {
    color: #333333;
	font-size: 20px;
	font-weight: normal;
    line-height: 27px;
    cursor: pointer;
    position: relative;
    padding-left: 50px;
    user-select: none;
    -webkit-tap-highlight-color: transparent; /* removes tap highlight on Chrome */
}
.checkbox label:active {
    background-color: transparent; /* removes tap highlight on Firefox */
}
.checkbox input {
    display: none;
}
.checkbox .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
	width: 40px;
	background-color: #bdbdbd;
    transition: background-color 500ms, opacity 500ms;
}
.checkbox input:checked ~ .checkmark {
	background-color: #ffc106;
}
.checkbox .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
.checkbox input:checked ~ .checkmark .checkmark-in {
    display:inline-block;
    width: 22px;
    height:30px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    position: absolute;
    right: 10px;
}
.checkbox input:checked ~ .checkmark .checkmark_stem {
    position: absolute;
    width: 3px;
    height: 15px;
    left: 11px;
    background-color: #fff;
    top: 6px;
}
.checkbox input:checked ~ .checkmark .checkmark_kick {
    position: absolute;
    width: 8px;
    height: 3px;
    background-color: #fff;
    left: 6px;
    top: 19px;
}
.forgot {
    font-size: 20px;
    color: #ffc107;
    cursor: pointer;
    font-weight: 900;
}
.forgot:hover {
    text-decoration: underline;
}
.login button {
    height: 80px;
    border: none;
    border-radius: 0;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    color: #212121;
    font-family: 'Avenir-Medium', sans-serif;
    background-color: #ffc106;
    transition: background-color 500ms, opacity 500ms;
    cursor: pointer;
}
.login button:disabled {
    opacity: 0.7;
    background-color: #ffd54f;
    cursor: not-allowed;
}
.login p {
    font-family: 'Avenir-Book', sans-serif;
    font-size: 16px;
    color: #333333;
    margin: 30px 0 10px;
}

@media screen and (max-width: 768px) {
    .login form {
        width: 100%;
    }
    .forgot {
        font-size: 16px;
    }
}
</style>