<template>
    <div class="loader" v-if="trigger"></div>
</template>

<script>
export default {
    name: 'Loading',
    props: ['trigger']
}
</script>

<style>
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: url('../../assets/preloader.gif') 50% 50% no-repeat rgb(249, 249, 249,0.7);
    opacity: 1;
}
</style>