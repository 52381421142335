<template>
    <ModalTemplate :title="forgotPasswordTitle" :trigger="trigger" :toggle="cleanAndToggle">
        <p>Please, type your e-mail below to receive instructions on how to reset your password.</p>
        <form @submit="recoverPassword" autocomplete="off">
            <input type="email" v-model.trim="email" placeholder="Email" @keyup="checkEmail" />
            <button type="submit" :disabled="isDisabled">Send</button>
        </form>
    </ModalTemplate>
</template>

<script>
import firebase from 'firebase'
import ModalTemplate from '../Modals/ModalTemplate'

export default {
    name: 'ForgotPassword',
    components: {
        ModalTemplate
    },
    props: ['trigger', 'toggle'],
    data() {
        return {
            email: '',
            isDisabled: true,
            forgotPasswordTitle: 'Send me a recovery link'
        }
    },
    methods: {
        checkEmail(e) {
            let re = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/gi
            this.isDisabled = re.test(e.target.value) ? false : true
        },
        async recoverPassword(e) {
            e.preventDefault()
            this.$emit('processing')
            try {
                let auth = firebase.auth()
                await auth.sendPasswordResetEmail(this.email)
                this.$emit('success', 'forgot')
                this.email = ''
                this.isDisabled = true
            } catch (error) {
                this.$emit('error', 'forgot')
            }
        },
        cleanAndToggle() {
            this.toggle()
            this.email = ''
        }
    }
}
</script>

<style scoped>
form {
	flex: 1 0;
	overflow: hidden;
}
form input {
    background: #bdbdbd;
    border: none;
    border-radius: 0;
    height: 80px;
    font-size: 20px;
    color: #333;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'Avenir-Medium';
    font-weight: 500;
    display: block;
    width: 100%;
    line-height: 1.42857143;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
form input::placeholder {
    opacity: 1;
    font-family: 'Avenir-Book', sans-serif;
    color: #333;
}
form input:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgb(255, 226, 132);
}
form button {
    background-color: #ffc106;
    height: 80px;
    width: 100%;
    border: none;
    border-radius: 0;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 40px;
    font-weight: 900;
    font-family: 'Avenir-Medium', sans-serif;
    transition: background-color 500ms, opacity 500ms;
    cursor: pointer;
}
form button:disabled {
    cursor: not-allowed;
    color: #212121;
    opacity: 0.7;
    background-color: #ffd54f;
}
</style>